import {
    createSlice
} from '@reduxjs/toolkit'; 
import Swal from 'sweetalert2'; 
import { Navigate, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const navigate = useNavigate;

const initialState = {
    user: {},
    token: "",
    solde:0,
    isAuthenticate: false, // default value 
};

export const auth = createSlice({
    name: 'auth',
    initialState,

    reducers: {
        // authentification 
        loginUser(state, action) {
            // reset data
            state.user = null;
            state.token = "";
            state.isAuthenticate = false; 
            const loginRequest = action.payload;
            state.user = loginRequest.user;
            console.log(loginRequest);
            state.token = loginRequest.authorisation.token;
            state.solde = loginRequest.solde;
            state.isAuthenticate = true;  
            // redirect to dashboard         
        },

        // authentification 
        resetAuthData(state) { 
            state.user = null;
            state.token = "";
            state.isAuthenticate = false; 
            //return navigate(myroutes.login)      
        },

        // debit 
        updateDebitSolde(state,action) { 
            state.solde -= action.payload.montant_debit;     
        },

        // credit 
        updateCreditSolde(state,action) { 
            state.solde += action.payload.montant_credit;  
        },


        // authentification 
        checkAuthenticate(state) {
            //console.log(action);
            let statut = false;  
            if (state.token) {
                const decodedToken = jwtDecode(state.token);
                const currentDate = new Date().getTime() / 1000; // Convertir la date actuelle en secondes 
                if (decodedToken.exp < currentDate) {
                    // Le token a expiré
                    statut = false;
                }else{
                    statut = true
                }  
            }  

            state.isAuthenticate = statut;

            if (!statut) {
                state.user = null;
                state.token = "";
                state.isAuthenticate = false; 
            }  

            // redirect to dashboard         
        }
    },
});

export const {
    loginUser,
    checkAuthenticate,
    resetAuthData,
    updateDebitSolde,
    updateCreditSolde
} = auth.actions;

export default auth.reducer;
import React, { useEffect, useState, useRef } from "react";
import Layout from "../inc/layout";
import { Link } from "react-router-dom";
import { myroute } from "../../Webroute";
import { Button } from 'primereact/button';
// validation des formulaires
import { liste, edit, create, remove } from "../../webservices/categories_ws";
import { liste as liste_typecategories } from "../../webservices/typecategories_ws";
import { liste as liste_statuts } from "../../webservices/statutcomponents_ws";
// date format
import moment from "moment";

// gestion datatable  
import { locale, addLocale, FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { activateComponent, disableComponent } from "../../webservices/api";
import { getUser, isAuthenticated } from "../../webservices/auth_ws";
import Swal from 'sweetalert2'; 
import { SplitButton } from 'primereact/splitbutton';
import { useSelector } from "react-redux";

// end gestion datatable

export default function IndexCategories(props) {

    // vérifier si l'utilisateur est connecter
    //const login = localStorage.getItem("isLoggedIn"); 
    addLocale('fr', {
        "startsWith": "Commence par",
        "contains": "Contient",
        "notContains": "Ne contient pas",
        "endsWith": "Se termine par",
        "equals": "Égal à",
        "notEquals": "Différent de",
        "noFilter": "Aucun filtre",
        "filter": "Filtre",  // only available for PrimeReact
        "lt": "Inférieur à",
        "lte": "Inférieur ou égal à",
        "gt": "Supérieur à",
        "gte": "Supérieur ou égal à",
        "dateIs": "La date est",
        "dateIsNot": "La date n'est pas",
        "dateBefore": "Avant le",
        "dateAfter": "Après le",
        "custom": "Personnalisé",
        "clear": "Effacer",
        "apply": "Appliquer",
        "matchAll": "Correspond à tous",
        "matchAny": "Au moins un Correspond",
        "addRule": "Ajouter une règle",
        "removeRule": "Retirer une règle",
    });

    locale('fr');

    const [user, setUser] = useState(null);
    const token = useSelector(state => state.auth.token)
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [categories, setCategories] = useState([])
    const [categorie, setCategorie] = useState({ id: '', libelle: '', typecategorie_id:'', description: '', statut_component: '',libelle_statut:'', libelle_typecategorie:'' })

    const [showModal, setShow] = useState(false);
    const [modalStatut, setModalStatut] = useState(false);
    const [modalCreateCategorie, setModalCreateCategorie] = useState(false);
    const [modalDeleteStatut, setModalDeleteStatut] = useState(false);
    const [modalDisableStatut, setModalDisableStatut] = useState(false);
    const [modalActivateStatut, setModalActivateStatut] = useState(false);

    const [selectedStatut, setSelectedStatut] = useState(null);
    const [statuts, setStatuts] = useState([]);

    const [selectedTypecategorie, setSelectedTypecategorie] = useState(null);
    const [typecategories, setTypecategories] = useState([]);

    const [itemDelete, setItemDelete] = useState();
    const [itemId, setItemId] = useState();
    // soumission du formulaire
    const [submitted, setSubmitted] = useState(false);
    const [erreur, setErreur] = useState(false);
    const toast = useRef(null);
    const handleClose = () => setShow(false);

    const hideModals = () => { setModalStatut(false); setSubmitted(false); };
    const hideModalsCreate = () => { setModalCreateCategorie(false); setSubmitted(false); };
    const hideModalsDelete = () => setModalDeleteStatut(false);
    const hideModalsDisable = () => setModalDisableStatut(false);
    const hideModalsActivate = () => setModalActivateStatut(false);

    const showModals = () => setModalStatut(true);
    const showModalsDelete = () => setModalDeleteStatut(true);
    const showModalCreate = () => setModalCreateCategorie(true)
    const showModalsDisable = () => setModalDisableStatut(true);
    const showModalsActivate = () => setModalActivateStatut(true);

    // déclaration des constantes pour gérer les erreurs
    const [erreurLibelle, setErreurLibelle] = useState({ status: false, message: '' })
    const [erreurCode, setErreurCode] = useState({ status: false, message: '' })
    const [erreurStatut, setErreurStatut] = useState({ status: false, message: '' })
    const [erreurTypecategorie, setErreurTypecategorie] = useState({ status: false, message: '' })

    //const userLogin = JSON.parse(localStorage.getItem("userData"));
    const navigate = useNavigate();

    // déclaration des boutons d'action
    const listeAction = (data) => {
        if (data.code_statut === "100") {
            return [
                {
                    label: 'Désactiver',
                    icon: 'pi pi-eye-slash',
                    command: () => handleDisable(data)
                }, 
                {
                    label: 'Supprimer',
                    icon: 'pi pi-trash',
                    command: () => handleDelete(data)
                }
            ];
        } else {
            return [
                {
                    label: 'Activer',
                    icon: 'pi pi-eye',
                    command: () => handleActivate(data)
                }, 
                {
                    label: 'Supprimer',
                    icon: 'pi pi-trash',
                    command: () => handleDelete(data)
                }
            ];
        }

    }


    //####### modal create footer 
    const modalCreateFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideModalsCreate} />
            <Button label="Valider" type="submit" icon="pi pi-save" onClick={() => createCategorie()} />
        </React.Fragment>
    );
    //###### end modal create footer


    //####### modal edit footer 
    const modalEditFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideModals} />
            <Button label="Valider" type="submit" icon="pi pi-check" onClick={() => saveEdit()} />
        </React.Fragment>
    );
    //###### end modal edit footer

    //####### modal delete footer 
    const modalDeleteFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" outlined onClick={hideModalsDelete} />
            <Button label="Oui" type="submit" icon="pi pi-check" onClick={() => deleteCategorie()} />
        </React.Fragment>
    );
    //###### end modal delete footer

    //####### modal disbale footer 
    const modalDisableFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" outlined onClick={hideModalsDisable} />
            <Button label="Oui" type="submit" icon="pi pi-check" onClick={() => disableCategorie()} />
        </React.Fragment>
    );
    //###### end modal disbale footer

    //####### modal activate footer 
    const modalActivateFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" outlined onClick={hideModalsActivate} />
            <Button label="Oui" type="submit" icon="pi pi-check" onClick={() => activateCategorie()} />
        </React.Fragment>
    );
    //###### end modal activate footer

    // mise à jour automatique de l'objet projet via le formulaire d'édition
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _categorie = { ...categorie };

        _categorie[`${name}`] = val;

        setCategorie(_categorie);

        // vérifier les données 
        if (name === "libelle") {
            if (val.trim().length === 0) {
                setErreurLibelle({ status: true, message: 'le champ libellé est obligatoire' });
            } else {
                setErreurLibelle({ status: false, message: '' });
            }
        } 
        if (name === "typecategorie_id") {
            if (val.trim().length === 0) {
                setErreurTypecategorie({ status: true, message: 'le champ type de catégorie est obligatoire' });
            } else {
                setErreurTypecategorie({ status: false, message: '' });
            }
        }
        if (name === "statut_component") {
            if (val.trim().length === 0) {
                setErreurStatut({ status: true, message: 'le champ statut est obligatoire' });
            } else {
                setErreurStatut({ status: false, message: '' });
            }
        }
    };

    // mise à jour automatique de l'objet categorie->typecategorie_id via le formulaire d'édition
    const onChangeTypecategorie = (value) => {
        let _categorie = { ...categorie };
        _categorie['typecategorie_id'] = value.code;
        setCategorie(_categorie); 
        setSelectedTypecategorie(value); 
        if (value.code.length === 0) {
            setErreurTypecategorie({ status: true, message: 'le champ type de catégorie est obligatoire' });
        } else {
            setErreurTypecategorie({ status: false, message: '' });
        }
    };

    // mise à jour automatique de l'objet categorie->statut_component via le formulaire d'édition
    const onChangeStatut = (value) => {
        let _categorie = { ...categorie };
        _categorie['statut_component'] = value.code;
        setCategorie(_categorie);
        setSelectedStatut(value);
        if (value.code.length === 0) {
            setErreurStatut({ status: true, message: 'le champ statut est obligatoire' });
        } else {
            setErreurStatut({ status: false, message: '' });
        }
    };

    // afficher le formulaire d'édition de categorie
    const handleShow = (categorie) => {
        setSubmitted(false);
        setCategorie({ ...categorie });
        // afficher le default type de catégorie
        setSelectedTypecategorie({ name: categorie.libelle_typecategorie, code: categorie.typecategorie_id })
        // afficher le default statut
        setSelectedStatut({ name: categorie.libelle_statut, code: categorie.statut_component })
        // afficher le modal
        //console.log(typecategorie)

        showModals()

        //$('#editStatutModal').modal('show')
    };

    // afficher le formulaire de création de statut
    const handleCreate = () => {
        setSubmitted(false);
        // initialisation de l'objet
        setCategorie({ id: '', libelle: '', description: '', statut_component: '', typecategorie_id:'' });
        setSelectedTypecategorie({ name: '', code: '' });
        setSelectedStatut({ name: '', code: '' });
        // afficher le modal 
        showModalCreate()
    };


    // afficher la boîte de confirmation de la suppression de l'élément
    const handleDelete = (categorie) => {
        setItemDelete(categorie.id)
        showModalsDelete()
    };

    // afficher la boîte de confirmation de la désactivation de l'élément
    const handleDisable = (categorie) => {
        setItemId(categorie.id)
        showModalsDisable()
    };

    // afficher la boîte de confirmation de la désactivation de l'élément
    const handleActivate = (categorie) => {
        setItemId(categorie.id)
        showModalsActivate()
    };


    // liste des categories  
    const fetchCategories = async () => { // 
        await liste(token).then(({ data }) => { 
            setCategories(data.categories)
            //console.log(data.categorie)
        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.status)
                /*Swal.fire({
                    text: "Erreur 400 ",
                    icon: "error"
                })*/
            } else { 

            }
        })
    }

    // récupérer la liste des types de catégorie
    const fetchTypecategories = async () => {
        await liste_typecategories(token).then(({ data }) => { 
            if (data.typecategories.length > 0) {
                const optionTypecategorie = data.typecategories.map((option, index) => {
                    //console.log(option.libelle)
                    return ({ name: option.libelle, code: option.id })
                }) 
                setTypecategories(optionTypecategorie)
            }

        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.status)
                /*Swal.fire({
                    text: "Erreur 400 ",
                    icon: "error"
                })*/
            } else {
                console.log(response)
                /*Swal.fire({
                    text: "Erreur lors de chargement des typecategorie",
                    icon: "error"
                })*/
            }
        })
    }

    // récupérer la liste des statuts
    const fetchStatuts = async () => {
        await liste_statuts(token).then(({ data }) => { 
            if (data.statutcomponents.length > 0) {
                const optionStatut = data.statutcomponents.map((option, index) => {
                    //console.log(option.libelle)
                    return ({ name: option.libelle, code: option.id })
                })
                //console.log(optionStatut)
                setStatuts(optionStatut)
            }

        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.status)
                /*Swal.fire({
                    text: "Erreur 400 ",
                    icon: "error"
                })*/
            } else {
                console.log(response)
                /*Swal.fire({
                    text: "Erreur lors de chargement des typecategorie",
                    icon: "error"
                })*/
            }
        })
    }

    useEffect(() => { 

        fetchCategories();
        fetchTypecategories();
        initFilters();
        fetchStatuts();
    }, [navigate])


    // script d'enregistrement des données
    const createCategorie = async () => {
        setErreur(false);
        let check_erreur = false
        // vérifier si tous les champs sont reneignées
        if (categorie.libelle.trim().length === 0) {
            setErreur(true);
            check_erreur = true 

            setErreurLibelle({ status: true, message: 'le champ libellé est obligatoire' });

        } 

        if (categorie.typecategorie_id.length === 0) {
            setErreur(true);
            check_erreur = true
            //toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ statut est obligatoire', life: 3000 });
            setErreurTypecategorie({ status: true, message: 'le champ typde de catégorie est obligatoire' });

        }

        if (categorie.statut_component.length === 0) {
            setErreur(true);
            check_erreur = true
            //toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ statut est obligatoire', life: 3000 });
            setErreurStatut({ status: true, message: 'le champ statut est obligatoire' });

        }

        setSubmitted(true);
        //e.preventDefault(); 

        if (check_erreur === true) {
            setModalCreateCategorie(true);
        } else {
            const formData = new FormData()
            formData.append("libelle", categorie.libelle) 
            formData.append("typecategorie_id", categorie.typecategorie_id)
            formData.append("statut_component", categorie.statut_component)
            formData.append("description", categorie.description) 

            await create(formData, token).then(({ data }) => {

                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchCategories();
                setModalCreateCategorie(false);
            }).catch(({ response }) => {
                if (response.status === 422) {
                    setModalCreateCategorie(true);
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                    setModalCreateCategorie(true);
                }
            })
            //console.log(erreur)
        }
    }

    // script de modification des données
    const saveEdit = async () => {
        setErreur(false);
        // vérifier si tous les champs sont reneignées
        if (categorie.libelle.trim().length === 0) {
            setErreur(true);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ libellé est obligatoire', life: 3000 });

        } 
        if (categorie.typecategorie_id === "") {
            setErreur(true);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ typde de catégorie est obligatoire', life: 3000 });
        } 
        setSubmitted(true);
        //e.preventDefault(); 

        if (erreur) {
            setModalStatut(true);
        } else {
            const formData = new FormData()
            formData.append("identifiant", categorie.id)
            formData.append("libelle", categorie.libelle) 
            formData.append("typecategorie_id", categorie.typecategorie_id)
            formData.append("description", categorie.description)

            await edit(formData, token).then(({ data }) => {
                /*Swal.fire({
                  icon:"success",
                  text:data.message,
                })*/
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchTypecategories();
                setModalStatut(false);
            }).catch(({ response }) => {
                if (response.status === 422) {
                    setModalStatut(true);
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                    setModalStatut(true);
                }
            })
            //console.log(erreur)
        }
    }

    // script de suppression des données
    const deleteCategorie = async () => {
        if (itemDelete !== null) {
            const formData = new FormData();
            formData.append("identifiant", itemDelete)
            await remove(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchCategories();
            }).catch(({ response }) => {
                if (response.status === 422) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                }
            })

        } else {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Type catégorie non reconnu", life: 3000 });
        }
        // cacher le modal
        setItemDelete(null);
        setModalDeleteStatut(false);

    }


    // script de désactivation des données
    const disableCategorie = async () => {
        if (itemId !== null) {
            const formData = new FormData();
            formData.append("identifiant", itemId)
            formData.append("table", "categories") 
            await disableComponent(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchCategories();
            }).catch(({ response }) => {
                if (response.status === 422) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                }
            })

        } else {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Type catégorie non reconnu", life: 3000 });
        }
        // cacher le modal
        setItemId(null);
        setModalDisableStatut(false);

    }

    // script d'activation des données
    const activateCategorie = async () => {
        if (itemId !== null) {
            const formData = new FormData();
            formData.append("identifiant", itemId)
            formData.append("table", "categories")
            await activateComponent(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchTypecategories();
            }).catch(({ response }) => {
                if (response.status === 422) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                }
            })

        } else {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Type catégorie non reconnu", life: 3000 });
        }
        // cacher le modal
        setItemId(null);
        setModalActivateStatut(false);

    }

  

    const dateUpdatedBodyTemplate = (data) => {
        return moment(data.updated_at).format("DD/MM/YYYY H:m:s") + ' par ' + data.name;
    };

    // custom statut badge
    const statutBodyTemplate = (data) => {
        return <Tag value={data.libelle_statut} severity={getSeverity(data)}></Tag>;
    };

    const getSeverity = (data) => {
        switch (data.code_statut) {
            case "100":
                return 'success';

            default:
                return 'danger';
        }
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            libelle: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            libelle_typecategorie: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            description: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updated_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            libelle_statut: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue('');
    };

    const actionBodyTemplate = (data) => {
        return (
            <React.Fragment>
                <div className="flex justify-content-between text-center">
                <SplitButton label="Modifier" icon="pi pi-pencil" onClick={() => handleShow(data)} model={listeAction(data)} severity="secondary" rounded />
                </div>
            </React.Fragment>
        );
    };


    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Effacer" outlined onClick={clearFilter} /> &nbsp;
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Recherche fulltext" />
                </span>&nbsp;&nbsp;
                <Button onClick={() => handleCreate()} type="button" icon="pi pi-save" severity="wan" tooltip="Ajouter" rounded />
            </div>
        );
    };
    // entête du tablea
    const header = renderHeader();

    return (
        <React.Fragment>
            <Layout>
                <Toast ref={toast} />
                <ConfirmDialog />
                <h1 className="h3 mb-2 text-gray-800">Catégories</h1>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                    <div className="row"> 
                            <div className="col-md-3">
                            <h6 className="m-0 font-weight-bold text-primary">Liste des catégories </h6>  
                            </div>
                            <div className="col-md-9 text-right">  
                            <Link to={myroute.parametres}>Paramètres</Link>&nbsp; / &nbsp; 
                            <Link href="#" style={{textDecoration:"none"}} className="active" aria-current="page">Catégories</Link> 
                            </div> 
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="card">
                                <DataTable value={categories} paginator showGridlines rows={10} loading={loading} dataKey="id"
                                    filters={filters} globalFilterFields={['libelle','libelle_typecategorie', 'description', 'updated_at', 'libelle_statut']} header={header}
                                    tableStyle={{ minWidth: '50rem' }}
                                    emptyMessage="Aucune données disponibles.">
                                    <Column field="libelle" sortable header="Libellé" filter filterPlaceholder="Recherche par libellé" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                    <Column field="libelle_typecategorie" sortable header="Type" filter filterPlaceholder="Recherche par type" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                     <Column field="description" sortable header="Description" filter filterPlaceholder="Recherche par description" style={{ minWidth: '12rem' }} />
                                    <Column field="updated_at" body={dateUpdatedBodyTemplate} sortable header="Date modification" filter style={{ minWidth: '12rem' }} />
                                    <Column field="libelle_statut" body={statutBodyTemplate} sortable header="Statut" filter filterPlaceholder="Recherche par statut" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                    <Column body={actionBodyTemplate} headerStyle={{ textAlign: 'center' }} header="Action" exportable={false} style={{ minWidth: '8rem' }}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>

                {/** formulaire de création */}
                <Dialog visible={modalCreateCategorie} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Ajouter une catégorie" className="p-fluid" onHide={hideModalsCreate} footer={modalCreateFooter}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="typecategorie" className="font-bold">
                                        Type de catégorie
                                    </label>
                                    <Dropdown required placeholder="Choisissez une option" name="typecategorie_id" inputId="typecategorie_id" value={selectedTypecategorie} onChange={(e) => onChangeTypecategorie(e.value)} className={classNames({ 'p-invalid': erreurTypecategorie.status })} options={typecategories} optionLabel="name"
                                        filter />
                                    {erreurTypecategorie.status && <small className="p-error">{erreurTypecategorie.message}</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Libellé <i className="text-danger">*</i>
                                    </label>
                                    <InputText height={1} id="libelle" name="libelle" value={categorie.libelle} onChange={(e) => onInputChange(e, 'libelle')} required autoFocus className={classNames({ 'p-invalid': erreurLibelle.status })} />
                                    {erreurLibelle.status && <small className="p-error">{erreurLibelle.message}</small>}
                                </div> 
                            </div>
                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Description
                                    </label>
                                    <InputTextarea id="description" name="description" value={categorie.description} onChange={(e) => onInputChange(e, 'description')} required autoFocus />
                                </div>
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Statut
                                    </label>
                                    <Dropdown required placeholder="Choisissez une option" name="statut_component" inputId="statut_component" value={selectedStatut} onChange={(e) => onChangeStatut(e.value)} className={classNames({ 'p-invalid': erreurStatut.status })} options={statuts} optionLabel="name"
                                        filter />
                                    {erreurStatut.status && <small className="p-error">{erreurStatut.message}</small>}
                                </div>
                            </div>

                        </div>
                    </div>
                </Dialog>
                {/** end formulaire de création */}

                {/** formulaire de modification **/}

                <Dialog visible={modalStatut} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Modifier le type de catégorie" className="p-fluid" onHide={hideModals} footer={modalEditFooter}>
                    <div className="confirmation-content">
                        <div className="row">

                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="typecategorie" className="font-bold">
                                        Type de catégorie
                                    </label>
                                    <Dropdown required placeholder="Choisissez une option" name="typecategorie_id" inputId="typecategorie_id" value={selectedTypecategorie} onChange={(e) => onChangeTypecategorie(e.value)} className={classNames({ 'p-invalid': erreurTypecategorie.status })} options={typecategories} optionLabel="name"
                                        filter />
                                    {erreurTypecategorie.status && <small className="p-error">{erreurTypecategorie.message}</small>}
                                </div>
                                <div className="field mb-3">
                                    <label htmlFor="name" className="font-bold">
                                        <b>Libellé <i className="text-danger">*</i></b>
                                    </label>
                                    <div className="p-inputgroup flex-1">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-book"></i>
                                        </span>
                                        <InputText height={1} id="libelle" name="libelle" value={categorie.libelle} onChange={(e) => onInputChange(e, 'libelle')} className={classNames({ 'p-invalid': erreurLibelle.status })} required autoFocus />
                                        {erreurLibelle.status && <small className="p-error">{erreurLibelle.message}</small>}
                                    </div>
                                    <InputText type="hidden" placeholder="Saisissez le libellé" height={1} id="id" name="id" value={categorie.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus />
                                </div> 
                            </div>
                            <div className="form-group col-md-12"> 
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        <b> Description <i className="text-danger"></i></b>
                                    </label>
                                    <InputTextarea id="description" name="description" value={categorie.description} onChange={(e) => onInputChange(e, 'description')} autoFocus />
                                </div>
                            </div>

                        </div>
                    </div>
                </Dialog>
                {/** end formulaire de modification **/}

                {/** boîte de supression **/}
                <Dialog visible={modalDeleteStatut} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmation" modal footer={modalDeleteFooter} onHide={hideModalsDelete}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {categorie && <span>Êtes-vous sûre de vouloir supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                {/** end boîte de supression **/}

                {/** boîte de désactivation **/}
                <Dialog visible={modalDisableStatut} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmation" modal footer={modalDisableFooter} onHide={hideModalsDisable}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {categorie && <span>Êtes-vous sûre de vouloir désactiver cet élément ?</span>}
                    </div>
                </Dialog>
                {/** end boîte de désactivation **/}

                {/** boîte d'activation **/}
                <Dialog visible={modalActivateStatut} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmation" modal footer={modalActivateFooter} onHide={hideModalsActivate}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {categorie && <span>Êtes-vous sûre de vouloir activer cet élément ?</span>}
                    </div>
                </Dialog>
                {/** end boîte d'activation **/}
            </Layout>
        </React.Fragment>
    )
}
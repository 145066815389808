import {  BrowserRouter,  Routes,  Route } from "react-router-dom";
import Dashboard from "./components/dashboard.jsx";
import IndexProfil from "./components/profils/indexProfils.jsx"; 
import IndexStatuts from "./components/statuts/indexStatuts.jsx";
import Parametres from "./components/parametres.jsx";
import Login from "./components/auth/login.jsx";
import IndexTypecategories from "./components/typecategories/indexTypecategories.jsx";
import IndexTypeoperations from "./components/typeoperations/indexTypeoperations.jsx";
import IndexStatutoperations from "./components/statutoperations/indexStatutoperations.jsx";
import IndexCategories from "./components/categories/indexCategories.jsx";
import IndexUser from "./components/utilisateurs/indexUser.jsx";
import RubriqueUser from "./components/utilisateurs/rubriqueUsers.jsx";
import Monportefeuille from "./components/portefeuille/monportefeuille.jsx";
import Transports from "./components/transports/transports.jsx";
import Logements from "./components/logements/logements.jsx";
import Educations from "./components/educations/educations.jsx";
import PlanifierDepense from "./components/planifications/planifierDepenses.jsx";
import Index from "./components/accueil/index.jsx";
import Factures from "./components/factures/factures.jsx";
import Autredepenses from "./components/autredepenses/autredepenses.jsx";

// déclaration des noms des routes
const myroute = {
    dashboard: "/dashboard",
    //profils routes
    profils: "/profils", 
    //stauts component routes
    statutcomponents: "/statutcomponents", 
    //parametre route
    parametres: "/parametres", 
    //login route
    login: "/login", 
    back_login: "/login", 
    //typecategorie route
    typecategories: "/typecategories", 
    //typeoperations route
    typeoperations: "/typeoperations", 
    //statutoperations route
    statutoperations: "/statutoperations", 
    //categories route
    categories: "/categories", 
    //utilisateurs route
    utilisateurs: "/utilisateurs", 
    //mesrubriques route
    mesrubriques: "/mesrubriques", 
    //monportefeuille route
    monportefeuille: "/monportefeuille", 
    //transports route
    transports: "/transports", 
    //logements route
    logements: "/logements", 
    //educations route
    educations: "/educations", 
    //planifierdepense route
    planifierdepense: "/planifierdepense", 
    //acceuil route
    accueil : "/", 
    //factures route
    factures: "/factures", 
    //autre dépense route
    autredepenses: "/autredepenses", 
}

// exportation des routes name
export {myroute};

export default function Webroute() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path={myroute.dashboard } element={<Dashboard/>}  />
                <Route path={myroute.profils} element={<IndexProfil/>}  /> 
                <Route path={myroute.statutcomponents} element={<IndexStatuts/>}  />
                <Route path={myroute.parametres} element={<Parametres/>}  />
                <Route path={myroute.login} element={<Login/>}  />
                <Route path={myroute.back_login} element={<Login/>}  />
                <Route path={myroute.typecategories} element={<IndexTypecategories/>}  />
                <Route path={myroute.typeoperations} element={<IndexTypeoperations/>}  />
                <Route path={myroute.statutoperations} element={<IndexStatutoperations/>}  />
                <Route path={myroute.categories} element={<IndexCategories/>}  />
                <Route path={myroute.utilisateurs} element={<IndexUser/>}  />
                <Route path={myroute.mesrubriques} element={<RubriqueUser />}  />
                <Route path={myroute.monportefeuille} element={<Monportefeuille />}  />
                <Route path={myroute.logements} element={<Logements />}  />
                <Route path={myroute.educations} element={<Educations />}  />
                <Route path={myroute.transports} element={<Transports />}  />
                <Route path={myroute.planifierdepense} element={<PlanifierDepense />}  />
                <Route path={myroute.accueil} element={<Index />} />
                <Route path={myroute.factures} element={<Factures />} />
                <Route path={myroute.autredepenses} element={<Autredepenses />} />
            </Routes>
        </BrowserRouter>
    )
}
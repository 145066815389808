import React, { useEffect, useState } from "react"
import {  useNavigate } from 'react-router-dom';
import Footer from "./footer"
import Sidebar from "./sidebar"
import Topbar from "./topbar"
import { getUser, isAuthenticated } from "../../webservices/auth_ws";
const UserContext = React.createContext(null); // Créer un contexte utilisateur

export default function Layout({ children }) {

    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => { 
    }, [navigate]);


    return (
        <UserContext.Provider value={user}>
        <React.Fragment>
                {/** Page Wrapper **/}
                <div id="wrapper">

                    {/** Sidebar **/}
                    <Sidebar />
                    {/** End of Sidebar **/}

                    {/** Content Wrapper **/}
                    <div id="content-wrapper" className="d-flex flex-column">

                        {/** Main Content **/}
                        <div id="content">

                            {/** Topbar **/}
                            <Topbar />
                            {/** End of Topbar **/}

                            {/** Begin Page Content **/}
                            <div className="container-fluid"> 
                                {children}
                            </div>
                            {/** /.container-fluid **/}

                        </div>
                        {/** End of Main Content **/}

                        {/** Footer **/}
                        <Footer />
                        {/** End of Footer **/}

                    </div>
                    {/** End of Content Wrapper **/}

                </div>
                {/** End of Page Wrapper **/}
        </React.Fragment>
            </UserContext.Provider>
    )
}
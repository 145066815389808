import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authentification } from "../../webservices/auth_ws";
import { Toast } from 'primereact/toast';
import './style_login.css';
import { myroute } from "../../Webroute";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthenticate, loginUser } from "../app/providers/authSlice";

export default function Login(props) {

    const [redirect, setRedirect] = useState(false);
    const [formLogin, setFormlogin] = useState({ email: '', password: '' });
    //const [userData, setUserData] = useState({ token: '', email: '', nom: '', prenoms: '' });
    const [validationError, setValidationError] = useState({});
    const navigate = useNavigate();
    const toast = useRef(null); 
    const dispatch = useDispatch();
    const isAuthenticate = useSelector(state => state.auth.isAuthenticate);

        // end vérification de l'authentification
        dispatch(checkAuthenticate)
        if (isAuthenticate) { 
            navigate(myroute.dashboard)
            
        } 

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _form = { ...formLogin };

        _form[`${name}`] = val;

        setFormlogin(_form);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(formLogin)
        await authentification(formLogin).then(({ data }) => {

            if (data.status === "success") {
                // enregistrement des informations dans la table localstorage
                // Sauvegarder le token JWT dans le local storage
                dispatch(loginUser(data))
                //localStorage.setItem('token', data.authorisation.token);
                // Rediriger vers le dashboard ou une autre page après la connexion réussie
                //console.log(data)
               // window.location.href = '/dashboard';
            } else {
                toast.current.show({ severity: 'error', summary: 'Erreur ccc', detail: data.message, life: 3000 });
            }

            // navigate("/statuts")
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
                toast.current.show({ severity: 'error', summary: 'Erreur ', detail: response.data.message, life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Erreur ', detail: response.data.message, life: 3000 });
            }
        })

    }


    return (
        <React.Fragment>
            <Toast ref={toast} />
            <div className="wrapper bg-white mx-auto">
                <div className="h4 text-center">BIENVENUE SUR HOUEFA</div>
                <form className="pt-3" onSubmit={handleSubmit}>
                    <div className="form-group py-2">
                        <div className="input-field"> <span className="far fa-user p-2"></span> <input type="email" placeholder="Entrer votre e-mail" required className="" value={formLogin.email} onChange={(e) => onInputChange(e, 'email')} /> </div>
                    </div>
                    <div className="form-group py-1 pb-2">
                        <div className="input-field"> <span className="fas fa-lock p-2"></span> <input type="password" placeholder="Entrer votre mot de passe" required className="" value={formLogin.password} onChange={(e) => onInputChange(e, 'password')}  />
                            {/* <span className="far fa-eye-slash"></span> */}
                        </div>
                    </div>
                    <div className="d-flex align-items-start">
                    <div className="inputBox"> 
                        </div>
                        <div className="remember"> <label className="option text-muted"> Se souvenir de moi <input type="radio" name="radio" /> <span className="checkmark"></span> </label> </div>
                        <div className="ml-auto"> <Link to={myroute.accueil} id="forgot">Retour à l'accueil</Link> </div>
                    </div> <button className="btn btn-block text-center my-3">Se connecter</button>
                </form>
            </div>
        </React.Fragment>
    )
}
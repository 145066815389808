import { api } from "./api";

// liste des terminaisons (webservices) 

// liste
export const liste = (token) => {
    return api.get(`utilisateurs`,{ headers: { 'Authorization': 'Bearer '+token } });
}
 
// create
export const create = (data,token) => {
    return api.post(`inscription`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}
// edit
export const edit = (data, token) => {
    return api.post(`utilisateurs/edit`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}
// delete
export const remove = (data, token) => {
    return api.post(`utilisateurs/delete`, data,{ headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+token } });
}
// rubrique
export const mesrubrique = (token) => {
    return api.get(`mesrubriques`,{ headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+token } });
}

// store rubrique
export const storerubrique = (data, token) => {
    return api.post(`store`, data,{ headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+token } });
}

//remove rubrique
export const removerubrique = (data, token) => {
    return api.post(`remove`, data,{ headers: { 'Authorization': 'Bearer '+token } });
}
//liste categorie disponible
export const categoriedisponible = (token) => {
    return api.get(`listecategoriedisponible`,{ headers: { 'Authorization': 'Bearer '+token } });
}
import React, { useEffect, useState, useRef } from "react";
import Layout from "../inc/layout";
import { Link } from "react-router-dom";
import { myroute } from "../../Webroute";
import { Button } from 'primereact/button';
import axios from "axios";
import { liste, edit, create, remove } from "../../webservices/statutcomponents_ws";
// date format
import moment from "moment";

// gestion datatable  
import { locale, addLocale, FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'primereact/tag'; 
import { InputNumber } from 'primereact/inputnumber';
import { getUser, isAuthenticated } from "../../webservices/auth_ws";
import Swal from 'sweetalert2';
import { useSelector } from "react-redux";

// end gestion datatable

export default function IndexStatuts(props) {

    // vérifier si l'utilisateur est connecter
    //const login = localStorage.getItem("isLoggedIn"); 
    addLocale('fr', {
        "startsWith": "Commence par",
        "contains": "Contient",
        "notContains": "Ne contient pas",
        "endsWith": "Se termine par",
        "equals": "Égal à",
        "notEquals": "Différent de",
        "noFilter": "Aucun filtre",
        "filter": "Filtre",  // only available for PrimeReact
        "lt": "Inférieur à",
        "lte": "Inférieur ou égal à",
        "gt": "Supérieur à",
        "gte": "Supérieur ou égal à",
        "dateIs": "La date est",
        "dateIsNot": "La date n'est pas",
        "dateBefore": "Avant le",
        "dateAfter": "Après le",
        "custom": "Personnalisé",
        "clear": "Effacer",
        "apply": "Appliquer",
        "matchAll": "Correspond à tous",
        "matchAny": "Au moins un Correspond",
        "addRule": "Ajouter une règle",
        "removeRule": "Retirer une règle",
    });
    locale('fr');

    const [user, setUser] = useState(null);
    const token = useSelector(state => state.auth.token)
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [statutcomponents, setStatutcomponents] = useState([])
    const [statutcomponent, setStatutcomponent] = useState({ id:'', libelle: '', code:'', description: '' })
    const [showModal, setShow] = useState(false);

    const [modalStatut, setModalStatut] = useState(false);
    const [modalCreateStatut, setModalCreateStatut] = useState(false);
    const [modalDeleteStatut, setModalDeleteStatut] = useState(false);

    const [selectedStatut, setSelectedStatut] = useState(null);
    const [statuts, setStatuts] = useState([]);
    const [itemDelete, setItemDelete] = useState();
    // soumission du formulaire
    const [submitted, setSubmitted] = useState(false);
    const [erreur, setErreur] = useState(false);
    const toast = useRef(null);
    const handleClose = () => setShow(false);

    const hideModals = () => { setModalStatut(false); setSubmitted(false); };
    const hideModalsDelete = () => setModalDeleteStatut(false);
    const hideModalsCreate = () => { setModalCreateStatut(false); setSubmitted(false); };

    const showModals = () => setModalStatut(true);
    const showModalCreate = () => setModalCreateStatut(true)
    const showModalsDelete = () => setModalDeleteStatut(true);
    //const userLogin = JSON.parse(localStorage.getItem("userData"));
    const navigate = useNavigate();

        //####### modal create footer 
        const modalCreateFooter = (
            <React.Fragment>
                <Button label="Annuler" icon="pi pi-times" outlined onClick={hideModalsCreate} />
                <Button label="Valider" type="submit" icon="pi pi-save" onClick={() => createStatut()} />
            </React.Fragment>
        );
        //###### end modal create footer

    //####### modal edit footer 
    const modalEditFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideModals} />
            <Button label="Modifier" type="submit" icon="pi pi-check" onClick={() => saveEdit()} />
        </React.Fragment>
    );
    //###### end modal edit footer

    //####### modal detele footer 
    const modalDeleteFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideModalsDelete} />
            <Button label="Supprimer" severity="danger" type="submit" icon="pi pi-trash" onClick={() => deleteStatutcomponent()} />
        </React.Fragment>
    );
    //###### end modal delete footer

    // mise à jour automatique de l'objet statutcomponent via le formulaire d'édition
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _statutcomponent = { ...statutcomponent };

        _statutcomponent[`${name}`] = val;

        setStatutcomponent(_statutcomponent);
    };
 

    // afficher le formulaire de création de statut
    const handleCreate = () => {
        setSubmitted(false);
        // initialisation de l'objet
        setStatutcomponent({ id:'', libelle:'', code:'', description:'' }); 
        // afficher le modal 
        showModalCreate()
    };

        // afficher le formulaire d'édition de statut
        const handleShow = (statutcomponent) => {
            setSubmitted(false);
            setStatutcomponent({ ...statutcomponent }); 
            // afficher le modal 
            showModals() 
        };

    // afficher la boîte de confirmation de la suppression de l'élément
    const handleDelete = (statutcomponent) => {
        setItemDelete(statutcomponent.id)
        showModalsDelete()
    };


    // liste des statutcomponent  
    const fetchStatutcomponents = async () => { // 
        await liste(token).then(({ data }) => {
            setStatutcomponents(data.statutcomponents)
            //console.log(data.profils)
        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.status) 
                alert("erreur de chargement des données")
            } else {
                /*if (response.data.message === "Token has expired") {
                    localStorage.clear();
                    navigate("/login")
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error"
                    })
                }*/
                //console.log(response.message)

            }
        })
    } 

    useEffect(() => { 
        fetchStatutcomponents();
        initFilters(); 
    }, [navigate])

        // script d'enregistrement des données
        const createStatut = async () => {
            setErreur(false);
            // vérifier si tous les champs sont reneignées
            if (statutcomponent.libelle.trim().length === 0) {
                setErreur(true);
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ libellé est obligatoire', life: 3000 });
    
            }  
            console.log(statutcomponent.code)
            if (statutcomponent.code.length === 0) {
                setErreur(true);
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ code est obligatoire', life: 3000 });
    
            }
            setSubmitted(true);
            //e.preventDefault(); 
    
            if (erreur) {
                setModalStatut(true);
            } else {
    
            const formData = new FormData() 
            formData.append("libelle",statutcomponent.libelle)
            formData.append("code",statutcomponent.code)
            formData.append("description",statutcomponent.description)
            console.log(formData)
    
                await create(formData,token).then(({ data }) => {
                    
                    toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                    fetchStatutcomponents();
                    setModalCreateStatut(false);
                }).catch(({ response }) => {
                    if (response.status === 422) {
                        setModalCreateStatut(true);
                        toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
    
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
    
                        setModalCreateStatut(true);
                    }
                })
                //console.log(erreur)
            }
        }

        
    // script de modification des données
    const saveEdit = async () => {
        setErreur(false);
        // vérifier si tous les champs sont reneignées
        if (statutcomponent.libelle.trim().length === 0) {
            setErreur(true);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ libellé est obligatoire', life: 3000 });

        }  
        if (statutcomponent.code.trim().length === 0) {
            setErreur(true);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ code est obligatoire', life: 3000 });

        } 
        setSubmitted(true);
        //e.preventDefault(); 

        if (erreur) {
            setModalStatut(true);
        } else {

        const formData = new FormData()
        formData.append("identifiant",statutcomponent.id)
        formData.append("libelle",statutcomponent.libelle)
        formData.append("code",statutcomponent.code)
        formData.append("description",statutcomponent.description)

            await edit(formData,token).then(({ data }) => {
                
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchStatutcomponents();
                setModalStatut(false);
            }).catch(({ response }) => {
                if (response.status === 422) {
                    setModalStatut(true);
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                    setModalStatut(true);
                }
            })
            //console.log(erreur)
        }
    }

    // script de modification des données
    const deleteStatutcomponent = async () => {
        if (itemDelete !== null) {
            const formData = new FormData();
            formData.append("identifiant",itemDelete)
            console.log(formData)
            await remove(formData,token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchStatutcomponents();
            }).catch(({ response }) => {
                if (response.status === 422) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                }
            })

        } else {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Statut non reconnu", life: 3000 });
        }
        // cacher le modal
        setItemDelete(null);
        setModalDeleteStatut(false);

    }


    // fonction permettant de supprimer
    const acceptDelete = () => {
        //deleteProfil(id)
        toast.current.show({ severity: 'warn', summary: 'Ok', detail: 'You have rejected', life: 3000 });
    }

    const rejectDelete = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirm = (position, data) => {
        // récupérer l'id de l'élément à supprimer
        //setItemDelete(data.id)
        //alert(data.id) 
        confirmDialog({
            target: data.id,
            message: 'Do you want to delete this record ?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept: acceptDelete,
            reject: rejectDelete
        });

    };

    const dateUpdatedBodyTemplate = (data) => {
        return moment(data.updated_at).format("DD/MM/YYYY H:m:s");
    };

    // custom statut badge
    /*const statutBodyTemplate = (data) => {
        return <Tag value={data.libelle_statut} severity={getSeverity(data)}></Tag>;
    };*/

    /*const getSeverity = (data) => {
        switch (data.libelle_statut) {
            case 'ACTIF':
                return 'success';

            default:
                return 'danger';
        }
    };*/

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            libelle: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            description: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updated_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            /*'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            representative: { value: null, matchMode: FilterMatchMode.IN },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },*/
            //activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
            //verified: { value: null, matchMode: FilterMatchMode.EQUALS }
        });
        setGlobalFilterValue('');
    };

    const actionBodyTemplate = (data) => {
        return (
            <React.Fragment>
                <div className="flex justify-content-between text-center">
                    <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => handleShow(data)} />
                    <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => handleDelete(data)} />
                </div>
            </React.Fragment>
        );
    };


    const renderHeader = () => {
        return (
            <React.Fragment>
                <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Effacer" outlined onClick={clearFilter} /> &nbsp;
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Recherche fulltext" />
                </span>&nbsp;&nbsp;
                <Button onClick={()=> handleCreate()} type="button" icon="pi pi-save" severity="wan" tooltip="Ajouter" rounded />
            </div> 
            </React.Fragment>
        );
    };
    // entête du tablea
    const header = renderHeader();

    return (
        <React.Fragment>
            <Layout>
                <Toast ref={toast} />
                <ConfirmDialog />
                <h1 className="h3 mb-2 text-gray-800">Statut des composants</h1>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row"> 
                            <div className="col-md-3">
                            <h6 className="m-0 font-weight-bold text-primary">Liste des statuts </h6>  
                            </div>
                            <div className="col-md-9 text-right">  
                            <Link to={myroute.parametres}>Paramètres</Link>&nbsp; / &nbsp; 
                            <Link href="#" style={{textDecoration:"none"}} className="active" aria-current="page">Statut composant</Link> 
                            </div> 
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="card">
                                <DataTable value={statutcomponents} paginator showGridlines rows={10} loading={loading} dataKey="id"
                                    filters={filters} globalFilterFields={['libelle', 'description', 'updated_at','name', 'libelle_statut']} header={header}
                                    tableStyle={{ minWidth: '50rem' }}

                                    emptyMessage="Aucune données disponibles.">
                                    <Column field="libelle" sortable header="Libellé" filter filterPlaceholder="Search by libellé" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                    <Column field="code" sortable header="Code" filter filterPlaceholder="Search by code" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                    <Column field="description" sortable header="Description" filter filterPlaceholder="Search by description" style={{ minWidth: '12rem' }} />
                                    <Column field="updated_at" body={dateUpdatedBodyTemplate} sortable header="Date modification" filter style={{ minWidth: '12rem' }} />
                                    <Column field="name" sortable header="Acteur" filter filterPlaceholder="Search by acteur" style={{ minWidth: '12rem' }} />
                                  {/** <Column field="libelle_statut" body={statutBodyTemplate} sortable header="Statut" filter filterPlaceholder="Search by statut" style={{ minWidth: '12rem', textAlign: 'center' }} />**/} 
                                    <Column body={actionBodyTemplate} headerStyle={{ textAlign: 'center' }} header="Action" exportable={false} style={{ minWidth: '8rem' }}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>

                {/** formulaire de création */}
                <Dialog visible={modalCreateStatut} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Ajouter le statut" className="p-fluid" onHide={hideModalsCreate} footer={modalCreateFooter}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Libellé <i className="text-danger">*</i>
                                    </label>
                                    <InputText placeholder="Actif" height={1} id="libelle" name="libelle" value={statutcomponent.libelle} onChange={(e) => onInputChange(e, 'libelle')} required autoFocus maxLength={20} minLength={3} />
                                 </div>
                            </div>
                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Code <i className="text-danger">*</i>
                                    </label>
                                    <InputNumber placeholder="100" height={1} max={999} inputId="code" value={statutcomponent.code} onValueChange={(e) => onInputChange(e, 'code')} required autoFocus />
                                  </div>
                            </div>
                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Description
                                    </label>
                                    <InputTextarea id="description" name="description" value={statutcomponent.description} onChange={(e) => onInputChange(e, 'description')} maxLength={255} minLength={3} required autoFocus />
                                </div> 
                            </div>

                        </div>
                    </div>
                </Dialog>
                {/** end formulaire de création */}

                {/** formulaire de modification **/}
                <Dialog visible={modalStatut} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Modifier le statut" className="p-fluid" onHide={hideModals} footer={modalEditFooter}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Libellé <i className="text-danger">*</i>
                                    </label>
                                    <InputText height={1} id="libelle" name="libelle" value={statutcomponent.libelle} onChange={(e) => onInputChange(e, 'libelle')} required autoFocus maxLength={20} minLength={3} />
                                    <InputText type="hidden" height={1} id="id" name="id" value={statutcomponent.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus />
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Code <i className="text-danger">*</i>
                                    </label>
                                    <InputNumber height={1} max={999} inputId="code" value={statutcomponent.code} onChange={(e) => onInputChange(e, 'code')} required autoFocus />
                                  </div>
                            </div>
                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Description
                                    </label>
                                    <InputTextarea id="description" name="description" value={statutcomponent.description} onChange={(e) => onInputChange(e, 'description')} maxLength={255} minLength={3} required autoFocus />
                                </div> 
                            </div>

                        </div>
                    </div>
                </Dialog>
                {/** end formulaire de modification **/}

                {/** boîte de supression **/}
                <Dialog visible={modalDeleteStatut} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={modalDeleteFooter} onHide={hideModalsDelete}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {statutcomponent && <span>Êtes-vous sûre de vouloir supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                {/** end boîte de supression **/}
            </Layout>
        </React.Fragment>
    )
}
import React, { useEffect, useState } from "react"
import { Link, json } from "react-router-dom"
import { myroute } from "../../Webroute"
import { deconnexion, getUser, isAuthenticated } from "../../webservices/auth_ws";  
import { useSelector } from "react-redux";




export default function Sidebar(props) {
    const auth = useSelector(state => state.auth)  
console.log(auth)
    return(
        <React.Fragment>
<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

{/** Sidebar - Brand */}
<Link className="sidebar-brand d-flex align-items-center justify-content-center" to={myroute.dashboard}>
    <div className="sidebar-brand-icon rotate-n-15">
        <i className="fas fa-laugh-wink"></i>
    </div>
    <div className="sidebar-brand-text mx-3">HOUEFA <sup>V0.1</sup></div>
</Link>

{/** Divider */}
<hr className="sidebar-divider my-0"/>

{/** Nav Item - Dashboard */}
<li className="nav-item active">
    <Link className="nav-link" to={myroute.dashboard}>
        <i className="fas fa-fw fa-tachometer-alt"></i>
        <span>Tableau de board</span></Link>
</li>

{/** Divider */}
<hr className="sidebar-divider"/>

{ auth.user?.libelle_profil === "Utilisateur" ?
<React.Fragment>
<div className="sidebar-heading">
    Interface
</div>

 
<li className="nav-item">
    <Link className="nav-link" to={myroute.monportefeuille}>
        <i className="pi pi-cog"></i>
        <span>Mon portefeuille</span>
    </Link>
</li> 
 
 
{/** Divider */}
<hr className="sidebar-divider"/>

{/** Catégorie */}
<div className="sidebar-heading">
    Les rubriques
</div>

{/** Nav Item - Pages Collapse Menu Transport */}
<li className="nav-item">
    <Link className="nav-link" to={myroute.transports}>
        <i className="pi pi-car"></i>
        <span>Transport</span>
    </Link> 
</li>

{/** Nav Item - Pages Collapse Menu Logements */}
<li className="nav-item">
    <Link className="nav-link" to={myroute.logements}>
        <i className="pi pi-home"></i>
        <span>Logements</span>
    </Link>  
</li>
{/** Nav Item - Pages Collapse Menu Educations */}
<li className="nav-item">
    <Link className="nav-link" to={myroute.educations}>
        <i className="pi pi-folder-open"></i>
        <span>Educations</span>
    </Link>     
</li>
{/** Nav Item - Pages Collapse Menu Factures */}
<li className="nav-item">
    <Link className="nav-link" to={myroute.factures}>
        <i className="pi pi-folder-open"></i>
        <span>Factures</span>
    </Link>     
</li>
{/** Nav Item - Pages Collapse Menu Autre dépenses */}
<li className="nav-item">
    <Link className="nav-link" to={myroute.autredepenses}>
        <i className="pi pi-folder-open"></i>
        <span>Autres dépenses</span>
    </Link>     
</li>


{/** Nav Item - Pages Collapse Menu Factures */}
<li className="nav-item">
    <Link className="nav-link" to={myroute.planifierdepense}>
        <i className="pi pi-book"></i>
        <span>Planifier dépenses</span>
    </Link>   
</li>

</React.Fragment>

: '' }

{ ( (auth.user?.libelle_profil === "Administrateur") || (auth.user?.libelle_profil === "Super Admin") ) && 

<React.Fragment>

{/** Divider */}
<hr className="sidebar-divider d-none d-md-block"/>
{/** paramètres */}
<li className="nav-item">
    <Link className="nav-link" to={myroute.parametres}>
        <i className="pi pi-cog"></i>
        <span>Paramètres</span></Link>
</li> 

</React.Fragment>
}


{/**  Nav Item - Pages Collapse Menu Habillement  
<li className="nav-item">
    <Link className="nav-link" to={myroute.transports}>
        <i className="pi pi-shopping-cart"></i>
        <span>Habillement</span>
    </Link>    
</li>
*/}

{/** Nav Item - Pages Collapse Menu Loisir  
<li className="nav-item">
    <Link className="nav-link" to={myroute.transports}>
        <i className="pi pi-hourglass"></i>
        <span>Loisirs</span>
    </Link>      
</li> */}
{/** Divider */}
<hr className="sidebar-divider d-none d-md-block"/>
{/** Nav Item - Charts  
<li className="nav-item">
    <a className="nav-link" href="#">
        <i className="fas fa-fw fa-chart-area"></i>
        <span>Mes statistiques</span></a>
</li>*/}
{/** Divider */}
<hr className="sidebar-divider d-none d-md-block"/>
{/** rapports 
<li className="nav-item">
    <a className="nav-link" href="#">
        <i className="pi pi-print"></i>
        <span>Gestion des rapports</span></a>
</li>*/}


{/** Divider */}
<hr className="sidebar-divider d-none d-md-block"/>

{/** Sidebar Toggler (Sidebar) */}
<div className="text-center d-none d-md-inline">
    <button className="rounded-circle border-0" id="sidebarToggle"></button>
</div>

{/** Sidebar Message */}
<div className="sidebar-card d-none d-lg-flex">
    <img className="sidebar-card-illustration mb-2" src="img/undraw_rocket.svg" alt="..."/>
    <p className="text-center mb-2"><strong>HOUEFA</strong> Votre plateforme pour la gestion des dépenses personnelles avec prévision sur les dépenses futures.</p>
</div>

</ul>
        </React.Fragment>
    )
}
import { api } from "./api";

// liste des terminaisons (webservices) 

// liste
export const operationportefeuille = (token) => {
    return api.get(`portefeuille/operations`,{ headers: { 'Authorization': 'Bearer '+token } });
}

// liste
export const soldeportefeuille = (token) => {
    return api.get(`portefeuille/solde`,{ headers: { 'Authorization': 'Bearer '+token } });
}
 
 

// create
export const create_operation_portefeuille = (data,token) => {
    return api.post(`portefeuille/operation/create`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
} 
import React, { useEffect, useState, useRef } from "react";
import Layout from "../inc/layout";
import { Link } from "react-router-dom";
import { myroute } from "../../Webroute";
import { Button } from 'primereact/button';
// validation des formulaires 
// date format
import moment from "moment";

// gestion datatable  
import { locale, addLocale, FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { TreeSelect } from 'primereact/treeselect';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { getUser, isAuthenticated } from "../../webservices/auth_ws";
import Swal from 'sweetalert2';
import { removerubrique, storerubrique, mesrubrique, rubriquedisponible, categoriedisponible } from "../../webservices/users_ws";
import { MultiSelect } from 'primereact/multiselect';
import { useSelector } from "react-redux";
// end gestion datatable

export default function RubriqueUser(props) {

    // vérifier si l'utilisateur est connecter
    //const login = localStorage.getItem("isLoggedIn"); 
    addLocale('fr', {
        "startsWith": "Commence par",
        "contains": "Contient",
        "notContains": "Ne contient pas",
        "endsWith": "Se termine par",
        "equals": "Égal à",
        "notEquals": "Différent de",
        "noFilter": "Aucun filtre",
        "filter": "Filtre",  // only available for PrimeReact
        "lt": "Inférieur à",
        "lte": "Inférieur ou égal à",
        "gt": "Supérieur à",
        "gte": "Supérieur ou égal à",
        "dateIs": "La date est",
        "dateIsNot": "La date n'est pas",
        "dateBefore": "Avant le",
        "dateAfter": "Après le",
        "custom": "Personnalisé",
        "clear": "Effacer",
        "apply": "Appliquer",
        "matchAll": "Correspond à tous",
        "matchAny": "Au moins un Correspond",
        "addRule": "Ajouter une règle",
        "removeRule": "Retirer une règle",
    });

    locale('fr');

    const [user, setUser] = useState();
    const token = useSelector(state => state.auth.token)
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [rubriques, setRubriques] = useState([]);
    const [getcategoriedisponibles, setGetCategoriedisponibles] = useState([])
    const [categorie, setCategorie] = useState([]) 
    const [selectedCategorie, setSelectedCategorie] = useState([]);
    const [showModal, setShow] = useState(false);
    const [modalCreateRubrique, setModalCreateRubrique] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [itemId, setItemId] = useState();
    // soumission du formulaire
    const [submitted, setSubmitted] = useState(false);
    const [erreur, setErreur] = useState(false);
    const toast = useRef(null);
    const handleClose = () => setShow(false);

    const hideModals = () => { setModalCreateRubrique(false); setSubmitted(false); };
    const hideModalsDelete = () => setModalDelete(false);

    const showModals = () => setModalCreateRubrique(true);
    const showModalsDelete = () => setModalDelete(true);

    //const userLogin = JSON.parse(localStorage.getItem("userData"));
    const navigate = useNavigate();

    //####### modal create footer 
    const modalCreateFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideModals} />
            <Button label="Valider" type="submit" icon="pi pi-check" onClick={() => createRubrique()} />
        </React.Fragment>
    );
    //###### end modal edit footer

    //####### modal delete footer 
    const modalDeleteFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" outlined onClick={hideModalsDelete} />
            <Button label="Oui" type="submit" icon="pi pi-check" onClick={() => deleteRubrique()} />
        </React.Fragment>
    );
    //###### end modal delete footer 

    // mise à jour automatique de l'objet projet via le formulaire d'édition
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _categorie = { ...categorie };

        _categorie[`${name}`] = val;

        setCategorie(_categorie);
    };

    const onChangeCategorie = (value) => {
        setSelectedCategorie(value); 
        //console.log(selectedCategorie);
    };

    // afficher le formulaire de création de statut
    const handleCreate = () => {
        setSubmitted(false);
        // afficher le modal 
        showModals()
    };

    // afficher la boîte de confirmation de la suppression de l'élément
    const handleDelete = (rubrique) => {
        setItemId(rubrique.id)
        console.log(rubrique.id)
        showModalsDelete()
    };
 
    // liste des rubriques  
    const fetchRubriques = async () => { // 
        await mesrubrique(token).then(({ data }) => { 
            setRubriques(data.mesrubriques)
        }).catch(({ response }) => {
            if (response.status === 500) {
                console.log(response)
            }
        })
    }

    // liste des categorie disponibles  
    const fetchCategoriedisponibles = async () => { //  
        await categoriedisponible(token).then(({ data }) => {
            if (data.categories.length > 0) {
                const optionCategorie = data.categories.map((option, index) => {
                    //console.log(option.id)
                    return ({ code: option.id, name: option.libelle })
                })
                setGetCategoriedisponibles(optionCategorie)
            }
        }).catch(({ response }) => {
            if (response.status === 500) {
                console.log(response.status)
            }
        })
    }

    useEffect(() => { 
        fetchRubriques();
        fetchCategoriedisponibles();
        initFilters();
    }, [navigate])


    // script d'enregistrement des données
    const createRubrique = async () => {
        setErreur(false);
        let check_erreur = false
        // vérifier si tous les champs sont reneignées
        if (selectedCategorie.length === 0) {
            setErreur(true);
            check_erreur = true
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ catégoires est obligatoire', life: 3000 });

        }

        setSubmitted(true);
        //e.preventDefault(); 

        if (check_erreur === true) {
            setModalCreateRubrique(true);
        } else {
            const formData = new FormData() 
            formData.append("choixcategorie", JSON.stringify(selectedCategorie))
        
            await storerubrique(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchRubriques();
                setModalCreateRubrique(false);
            }).catch(({ response }) => {
                if (response.status === 422) {
                    setModalCreateRubrique(true);
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                    setModalCreateRubrique(true);
                }
            })
            //console.log(erreur)
        }
    }

    // script de suppression des données
    const deleteRubrique = async () => {
        if (itemId !== null) {
            const formData = new FormData();
            formData.append("rubrique_id", itemId)
            console.log(formData)
            await removerubrique(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchRubriques();
            }).catch(({ response }) => {
                if (response.status === 422) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                }
            })

        } else {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Type catégorie non reconnu", life: 3000 });
        }
        // cacher le modal
        setItemId(null);
        setModalDelete(false);

    }

    const dateUpdatedBodyTemplate = (data) => {
        return moment(data.updated_at).format("DD/MM/YYYY H:m:s") + ' par ' + data.name;
    };
 
    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            libelle: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            description: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updated_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue('');
    };

    const actionBodyTemplate = (data) => {
        return (
            <React.Fragment>
                <div className="flex justify-content-between text-center">
                    <Button severity="danger" type="button" icon="pi pi-trash" label="Supprimer" outlined onClick={()=> handleDelete(data)} />
                </div>
            </React.Fragment>
        );
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Effacer" outlined onClick={clearFilter} /> &nbsp;
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Recherche fulltext" />
                </span>&nbsp;&nbsp;
                <Button onClick={() => handleCreate()} type="button" icon="pi pi-save" severity="wan" tooltip="Ajouter" rounded />
            </div>
        );
    };
    // entête du tablea
    const header = renderHeader();

    return (
        <React.Fragment>
            <Layout>
                <Toast ref={toast} />
                <ConfirmDialog />
                <h1 className="h3 mb-2 text-gray-800">Mes rubriques</h1>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-md-3">
                                <h6 className="m-0 font-weight-bold text-primary">Liste des rubriques</h6>
                            </div>
                            <div className="col-md-9 text-right">
                                <Link to={myroute.parametres}>Paramètres</Link>&nbsp; / &nbsp;
                                <Link href="#" style={{ textDecoration: "none" }} className="active" aria-current="page">Mes rubriques</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="card">
                                <DataTable value={rubriques} paginator showGridlines rows={10} loading={loading} dataKey="id"
                                    filters={filters} globalFilterFields={['libelle', 'description', 'updated_at']} header={header}
                                    tableStyle={{ minWidth: '50rem' }}
                                    emptyMessage="Aucune données disponibles.">
                                    <Column field="libelle" sortable header="Libellé" filter filterPlaceholder="Recherche par libellé" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                    <Column field="description" sortable header="Description" filter filterPlaceholder="Recherche par description" style={{ minWidth: '12rem' }} />
                                    <Column field="updated_at" body={dateUpdatedBodyTemplate} sortable header="Date modification" filter style={{ minWidth: '12rem' }} />
                                    <Column body={actionBodyTemplate} headerStyle={{ textAlign: 'center' }} header="Action" exportable={false} style={{ minWidth: '8rem' }}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>

                {/** formulaire de création */}
                <Dialog visible={modalCreateRubrique} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Ajouter un type de catégorie" className="p-fluid" onHide={hideModals} footer={modalCreateFooter}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Catégories <i className="text-danger">*</i>
                                    </label>
                                    <MultiSelect value={selectedCategorie} onChange={(e) => onChangeCategorie(e.value)} options={getcategoriedisponibles}  filter optionLabel="name"
                                        placeholder="Select Cities" maxSelectedLabels={13} name="choixcategorie[]" className="w-full md:w-20rem" /> 

                                </div>
                            </div>

                        </div>
                    </div>
                </Dialog>
                {/** end formulaire de création */}



                {/** boîte de supression **/}
                <Dialog visible={modalDelete} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmation" modal footer={modalDeleteFooter} onHide={hideModalsDelete}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {categorie && <span>Êtes-vous sûre de vouloir supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                {/** end boîte de supression **/}

            </Layout>
        </React.Fragment>
    )
}
import { api } from "./api";

// liste des terminaisons (webservices) 

// liste
export const liste = (token) => {
    return api.get(`planificationsdepenses`,{ headers: { 'Authorization': 'Bearer '+token } });
} 
 
// create
export const create = (data,token) => {
    return api.post(`planificationsdepenses`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}

// edit
/*export const edit = (data,token) => {
    return api.post(`planificationsdepenses/edit`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}*/
 
// delete
export const remove = (data, token) => {
    return api.post(`planificationsdepenses/delete`, data,{ headers: { 'Authorization': 'Bearer '+token } });
}
import React from "react";
import  ReactDOM  from "react-dom/client";    
import Webroute from "./Webroute.js";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";        
//core
import "primereact/resources/primereact.min.css"; 
import 'primeicons/primeicons.css';   
import {Provider} from 'react-redux';  
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './components/app/store.js'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Webroute />
      </PersistGate>
    </Provider> 
  </React.StrictMode>
);
import React from "react"
export default function Footer(props) {
    return(
        <React.Fragment>
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; HOUEFA V0.1</span>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
import React, { useState, useEffect } from "react";
import Layout from "./inc/layout";
import { Chart } from 'primereact/chart';
import { dashboardIndicateurs } from "../webservices/api";
import { useSelector } from "react-redux";

export default function Dashboard(props) {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const token = useSelector(state => state.auth.token)
    const [indicateurs, setIndicateurs] = useState({});  

    const formatCurrency = (value) => {
        return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
    };

    // liste des indicateurs  
    const fetchIndicateurs = async () => { // 
        await dashboardIndicateurs(token).then(({ data }) => {

            setIndicateurs(data.total_depense)  
            
            const data_chart = {
                labels: data.total_depense.map((data) => data.libelle),
                datasets: [
                    {
                        label: 'Evolution des dépenses par catégorie',
                        data: data.total_depense.map((data) => data.montant_operation),
                        backgroundColor: [
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(153, 102, 255, 0.2)'
                        ],
                        borderColor: [
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)'
                        ],
                        borderWidth: 1
                    }
                ]
            };
            const options = {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            };
    
            setChartData(data_chart);
            setChartOptions(options);







        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.status)
            } else {

            }
        })
    }
 



    useEffect(() => {
        fetchIndicateurs();   
    }, []);

    return (
        <Layout>

            {/**  Page Heading **/}

            {/**  Content Row **/}
            <div className="row">

                {/**  Earnings (Monthly) Card Example **/}
                <React.Fragment>
                { Array.isArray(indicateurs) && indicateurs.map((options, index) => {
            return (
                <div className="col-xl-4 col-md-4 mb-4" key={index}>
                    <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Dépenses {options.libelle}</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{formatCurrency(parseInt(options.montant_operation))}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })  }
                </React.Fragment>   
            </div>

            {/**  Content Row **/}

            {/* session chart */}
            <div className="row">
                <div className="card col-md-12">
                    <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                </div>
            </div>
            {/* end session chart */}

        </Layout>
    )
}
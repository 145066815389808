import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { myroute } from "../../Webroute";

/*
import "../assets/vendor/aos/aos.css"; 
import "../assets/vendor/bootstrap/css/bootstrap.min.css"; 
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css"; 
import "../assets/vendor/boxicons/css/boxicons.min.css"; 
import "../assets/vendor/glightbox/css/glightbox.min.css"; 
import "../assets/vendor/swiper/swiper-bundle.min.css";  
import "../assets/css/style.css";
*/

export default function Index(props) { 
    const navigate = useNavigate(); 

    useEffect(() => {  
        
    }, []); 

    return (
        <React.Fragment>  
  <section id="topbar" className="d-flex align-items-center">
    <div className="container d-flex justify-content-center justify-content-md-between">
      <div className="contact-info d-flex align-items-center">
        <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@example.com">jpkounasso@gmail.com</a></i>
        <i className="bi bi-phone d-flex align-items-center ms-4"><span>+229 67 52 16 80</span></i>
      </div>

      <div className="social-links d-none d-md-flex align-items-center">
        <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
        <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
        <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
        <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
      </div>
    </div> 
  </section>
  <header id="header" className="d-flex align-items-center">
    <div className="container d-flex align-items-center justify-content-between">

      <h1 className="logo"><a href="index.html">HOUEFA<span></span></a></h1> 

      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Accueil</a></li>
          <li><a className="nav-link scrollto" href="#services">Rubriques</a></li>
          <li><a className="nav-link scrollto" href="#pricing">Tarif</a></li>
          <li><Link className="nav-link scrollto btn-get-started " to={myroute.login}>Se connecter</Link></li> 
          <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav> 

    </div>
  </header> 

   
  <section id="hero" className="d-flex align-items-center">
    <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <h1>Bienvenue sur <span>HOUEFA</span></h1>
      <h2>Votre plateforme de gestion des dépenses financières personnelles</h2>
      <div className="d-flex">
        <Link to={myroute.login} className="btn-get-started scrollto">Se connecter</Link>
      </div>
    </div>
  </section> 

  <main id="main">   
      
     
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">

        <div className="section-title"> 
          <h3>Nos <span>Rubriques</span></h3>
          <p>Catégorie de dépense financière</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bx bxl-dribbble"></i></div>
              <h4><a href="">EDUCATION</a></h4>
              <p>Gestion des dépenses financières liées à la scolarisation</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4><a href="">LOGEMENT</a></h4>
              <p>Gestion des dépenses financières liées au logement</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4><a href="">TRANSPORT</a></h4>
              <p>Gestion des dépenses financières liées au transport</p>
            </div>
          </div> 

        </div>

      </div>
    </section> 

    
    <section id="testimonials" className="testimonials">
      <div className="container" data-aos="zoom-in">

        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper">

            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src="front/assets/img/BANNIERE.jpeg" className="testimonial-img" alt=""/>
                <h3>HOUEFA</h3> 
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Votre plateforme de gestion des dépenses financières personnelles avec prévision sur les dépenses futures.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>   
 
          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>
    </section>   
 
    <section id="pricing" className="pricing">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
        <h2>Tarif</h2>
          <h3><span>Tarification</span></h3>
        </div>

        <div className="row">

          <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div className="box">
              <h3>ESSAIE</h3>
              <h4>0 <sup>F CFA</sup><span></span></h4>
              <ul>
                <li>Gestion de portefeuille</li>
                <li>Gestion des dépenses</li> <br /><br /><br /><br /><br /><br /><br /><br />
              </ul>
              <div className="btn-wrap">
              <Link to={myroute.login} className="btn-buy">Se connecter</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
            <div className="box featured">
              <h3>Business</h3>
              <h4>5.000 <sup>F CFA</sup><span> / mois</span></h4>
              <ul>
              <ul>
                <li>Gestion de portefeuille</li>
                <li>Gestion des dépenses</li>
                <li>Gestion de la planification des dépenses</li>
                <li>Edition des rapports</li>
                <li>Gestion des etats et statistiques</li> <br /><br />
              </ul>
              </ul>
              <div className="btn-wrap">
              <Link to={myroute.login} className="btn-buy">S'abonner</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
            <div className="box">
              <h3>Golden</h3>
              <h4>12.000 <sup>F CFA</sup><span> / semestriel</span></h4>
              <ul>
              <li>Gestion de portefeuille</li>
                <li>Gestion des dépenses</li>
                <li>Gestion de la planification des dépenses</li>
                <li>Edition des rapports</li>
                <li>Gestion des etats et statistiques</li>
              </ul>
              <div className="btn-wrap">
              <Link to={myroute.login} className="btn-buy">S'abonner</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
            <div className="box">
              <span className="advanced"></span>
              <h3>VIP</h3>
              <h4>50.000 <sup>F CFA</sup><span> / Annuel</span></h4>
              <ul>
              <li>Gestion de portefeuille</li>
                <li>Gestion des dépenses</li>
                <li>Gestion de la planification des dépenses</li>
                <li>Edition des rapports</li>
                <li>Gestion des etats et statistiques</li><br /><br />
              </ul>
              <div className="btn-wrap">
                <Link to={myroute.login} className="btn-buy">S'abonner</Link>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section> 
  
 
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Contact</h2> 
          <p>Formulaire de contact</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6">
            <div className="info-box mb-4">
              <i className="bx bx-map"></i>
              <h3>Notre Addresse</h3>
              <p>Porto-Novo, Bénin Carrefour cinquantenanire rue pharmacie Gbodjè</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-envelope"></i>
              <h3>Email</h3>
              <p>jpkounasso@gmail.com</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-phone-call"></i>
              <h3>Tél</h3>
              <p>+229 67 52 16 80</p>
            </div>
          </div>

        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">

          <div className="col-lg-6 ">
          <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d63427.57222532257!2d2.5892536927465417!3d6.493391047475817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1smap%20porto%20novo%20b%C3%A9nin%20iframe!5e0!3m2!1sfr!2sbj!4v1687789075276!5m2!1sfr!2sbj" allowFullScreen="" loading="lazy" style={{border:'0', width: '100%', height: '384px'}} referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="col-lg-6">
            <form action="#" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Nom & Prénoms" required/>
                </div>
                <div className="col form-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder="E-mail" required/>
                </div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Sujet" required/>
              </div>
              <div className="form-group">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Chargement</div>
                <div className="error-message"></div>
                <div className="sent-message">Message envoyé avec succès.</div>
              </div>
              <div className="text-center"><button type="submit">Envoyer</button></div>
            </form>
          </div>

        </div>

      </div>
    </section> 

  </main> 
 
  <footer id="footer">

    <div className="footer-newsletter">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6"> 
             
          </div>
        </div>
      </div>
    </div>

    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6 footer-contact">
            <h3>HOUEFA<span></span></h3>
            <p>
              Porto-Novo <br/>
              Bénin<br/>
              Carrefour cinquantenaire rue de la Pharmacie Gbodjè <br/><br/>
              <strong>Tél:</strong> +229 67 52 16 80<br/>
              <strong>E-mail:</strong> jpkounasso@gmail.com<br/>
            </p>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Lien</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Accueil</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Tarif</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Rubriques</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Se connecter</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Contact</a></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Nos rubriques</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Education</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Logement</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Transport</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Factures</a></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Réseaux sociaux</h4>
            <p>Retrouvez-nous sur </p>
            <div className="social-links mt-3">
              <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
              <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
              <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
              <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
              <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div className="container py-4">
      <div className="copyright">
        &copy; 2023 <strong><span>HOUEFA</span></strong>. Tous droits réservés
      </div>
      <div className="credits"> 
        Designed by <a href="https://houefa.kounasso.bj/">HOUEFA</a>
      </div>
    </div>
  </footer>


  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

        </React.Fragment>
    )
}
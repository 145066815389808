import axios from "axios";

   //export const BackendUrl = "http://127.0.0.1:8004/"
   export const BackendUrl = "https://api-houefa.kounasso.net/"

export const api = axios.create({
    baseURL: 'https://api-houefa.kounasso.net/api/', 
    //baseURL: 'http://127.0.0.1:8004/api/', 
    headers: {
        'Content-Type': 'application/json' , 
    }
})


// disable component
export const disableComponent = (data, token) => {
    return api.post(`update/disable`, data,{ headers: { 'Authorization': 'Bearer '+token } });
}

// activate component
export const activateComponent = (data, token) => {
    return api.post(`update/activate`, data,{ headers: { 'Authorization': 'Bearer '+token } });
}

// parametres component
export const parametres = (token) => {
    return api.get(`parametres`,{ headers: { 'Authorization': 'Bearer '+token } });
}

// dashboard indicateur component
export const dashboardIndicateurs = (token) => {
    return api.get(`dashboard/indicateurs`,{ headers: { 'Authorization': 'Bearer '+token } });
}

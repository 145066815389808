import React, { useEffect, useState } from "react";
import Layout from "./inc/layout";
import { myroute } from "../Webroute";
import { Link, useNavigate } from "react-router-dom";
import { getUser, isAuthenticated } from "../webservices/auth_ws";
import Swal from 'sweetalert2';
import { parametres } from "../webservices/api";
import { useSelector } from "react-redux";

export default function Parametres(props) {

    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token)
    const [compteurs, setCompteurs] = useState({ nbre_statutcomposant: 0, nbre_statutoperation: 0, nbre_typeoperation: 0, nbre_typecategorie: 0, nbre_categorie: 0, nbre_profil: 0, nbre_user: 0 });

    // liste des compteurs  
    const fetchCompteurs = async () => { // 
        await parametres(token).then(({ data }) => {
            setCompteurs({ nbre_statutcomposant: data.nbre_statutcomposant, nbre_statutoperation: data.nbre_statutoperation, nbre_typeoperation: data.nbre_typeoperation, nbre_typecategorie: data.nbre_typecategorie, nbre_categorie: data.nbre_categorie, nbre_profil: data.nbre_profil, nbre_user: data.nbre_user, nbre_mesrubriques: data.nbre_mesrubriques })
            //console.log(data.Statutoperation)
        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.status)
            } else {

            }
        })
    }

    useEffect(() => { 
        fetchCompteurs()

    }, [navigate]);

    return (
        <Layout>

            {/**  Page Heading **/}
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Paramètres</h1>
            </div>

            {/**  Content Row **/}
            <div className="row">

                {/**  statut composant **/}
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2 p-4">
                        <div className="card-body">
                            <Link to={myroute.statutcomponents} className="text-decoration-none">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Statut composant </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{compteurs.nbre_statutcomposant}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-eye fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                {/**  statut opération **/}
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2 p-4">
                        <div className="card-body">
                            <Link to={myroute.statutoperations} className="text-decoration-none">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Statut opération </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{compteurs.nbre_statutoperation}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="pi pi-book text-gray-300" style={{ fontSize: '2rem' }} ></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                {/**  catégorie opération **/}
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2 p-4">
                        <div className="card-body">
                            <Link to={myroute.typecategories} className="text-decoration-none">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Type de Catégorie </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{compteurs.nbre_categorie}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="pi pi-list text-gray-300" style={{ fontSize: '2rem' }} ></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                {/**  Type d'opération **/}
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2 p-4">
                        <div className="card-body">
                            <Link to={myroute.typeoperations} className="text-decoration-none">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Type d'opération </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{compteurs.nbre_typeoperation}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="pi pi-server text-gray-300" style={{ fontSize: '2rem' }} ></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                {/**  opération **/}
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2 p-4">
                        <div className="card-body">
                            <Link to={myroute.categories} className="text-decoration-none">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Catégories </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{compteurs.nbre_categorie}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="pi pi-inbox text-gray-300" style={{ fontSize: '2rem' }} ></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                {/**  profil **/}
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2 p-4">
                        <div className="card-body">
                            <Link to={myroute.profils} className="text-decoration-none">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Profils </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{compteurs.nbre_profil}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="pi pi-sitemap text-gray-300" style={{ fontSize: '2rem' }} ></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                {/**  compte utilisateur **/}
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2 p-4">
                        <div className="card-body">
                            <Link to={myroute.utilisateurs} className="text-decoration-none">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Compte utilisateur </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{compteurs.nbre_user}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="pi pi-users text-gray-300" style={{ fontSize: '2rem' }} ></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                {/** mes rubriques **/}
                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2 p-4">
                        <div className="card-body">
                            <Link to={myroute.mesrubriques} className="text-decoration-none">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Mes rubriques </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{compteurs.nbre_mesrubriques}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="pi pi-users text-gray-300" style={{ fontSize: '2rem' }} ></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>


            </div>

            {/**  Content Row **/}



        </Layout>
    )
}
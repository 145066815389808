import { api } from "./api";

// liste des terminaisons (webservices)

// liste
export const liste = (token) => {
    return api.get(`statutcomponents`,{ headers: { 'Authorization': 'Bearer '+token } });
}

// show
export const show = (data,token) => {
    return api.post(`statutcomponents/show`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}

// create
export const create = (data, token) => {
    return api.post(`statutcomponents`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}
// edit
export const edit = (data, token) => {
    return api.post(`statutcomponents/edit`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}
// delete
export const remove = (data, token) => {
    return api.post(`statutcomponents/delete`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}
import React, { useEffect, useState, useRef } from "react";
import Layout from "../inc/layout";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
// validation des formulaires 
// date format
import moment from "moment";

// gestion datatable  
import { locale, addLocale, FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { getUser, isAuthenticated } from "../../webservices/auth_ws";
import Swal from 'sweetalert2';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { create_operation_portefeuille, operationportefeuille, soldeportefeuille } from "../../webservices/portefeuille_ws";
import { useDispatch, useSelector } from "react-redux";
import { updateCreditSolde } from "../app/providers/authSlice";
// end gestion datatable

export default function Monportefeuille(props) {

    // vérifier si l'utilisateur est connecter
    //const login = localStorage.getItem("isLoggedIn"); 
    addLocale('fr', {
        "startsWith": "Commence par",
        "contains": "Contient",
        "notContains": "Ne contient pas",
        "endsWith": "Se termine par",
        "equals": "Égal à",
        "notEquals": "Différent de",
        "noFilter": "Aucun filtre",
        "filter": "Filtre",  // only available for PrimeReact
        "lt": "Inférieur à",
        "lte": "Inférieur ou égal à",
        "gt": "Supérieur à",
        "gte": "Supérieur ou égal à",
        "dateIs": "La date est",
        "dateIsNot": "La date n'est pas",
        "dateBefore": "Avant le",
        "dateAfter": "Après le",
        "custom": "Personnalisé",
        "clear": "Effacer",
        "apply": "Appliquer",
        "matchAll": "Correspond à tous",
        "matchAny": "Au moins un Correspond",
        "addRule": "Ajouter une règle",
        "removeRule": "Retirer une règle",
    });

    locale('fr');

    const [user, setUser] = useState();
    const token = useSelector(state => state.auth.token)
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [operations, setOperations] = useState([]);
    const [solde, setSolde] = useState(0);
    const [seuil, setSeuil] = useState(0);
    const [operation, setOperation] = useState({ montant_operation: 0, designation: 'Recharge', description: '', seuil: 0 });
    const [showModal, setShow] = useState(false);
    const [modalCreate, setModalCreate] = useState(false);
    // soumission du formulaire
    const [submitted, setSubmitted] = useState(false);
    const [erreur, setErreur] = useState(false);
    const toast = useRef(null);
    const handleClose = () => setShow(false);

    const hideModals = () => { setModalCreate(false); setSubmitted(false); };
    const showModals = () => setModalCreate(true);
    const dispatch = useDispatch()

    const navigate = useNavigate();

    //####### modal create footer 
    const modalCreateFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideModals} />
            <Button label="Valider" type="submit" icon="pi pi-check" onClick={() => createOperation()} />
        </React.Fragment>
    );
    //###### end modal edit footer


    // mise à jour automatique de l'objet projet via le formulaire d'édition
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _operation = { ...operation };

        _operation[`${name}`] = val;

        setOperation(_operation);
    };


    // afficher le formulaire de création de statut
    const handleCreate = () => {
        setSubmitted(false);
        // afficher le modal 
        showModals()
    };


    // liste des opérations  
    const fetchOperations = async () => { // 
        await operationportefeuille(token).then(({ data }) => {
            setOperations(data.operations)
            //console.log(data.operations)
        }).catch(({ response }) => {
            if (response.status === 500) {
                console.log(response)
            }
        })
    }

    // solde portefeuille  
    const fetchSolde = async () => { // 
        await soldeportefeuille(token).then(({ data }) => {
            setSolde(data.solde)
            setSeuil(data.seuil)
            let _operation = { ...operation };
            _operation['seuil'] = data.seuil;
            setOperation(_operation);
            //console.log(data.solde)
        }).catch(error => {
            console.log(error);
            // Gérer les erreurs de récupération des informations de l'utilisateur
        })
    }


    useEffect(() => { 
        fetchOperations();
        fetchSolde();
        initFilters();
    }, [navigate])


    // script d'enregistrement des données
    const createOperation = async () => {
        setErreur(false);
        let check_erreur = false
        // vérifier si tous les champs sont reneignées
        if (operation.montant_operation.length === 0) {
            setErreur(true);
            check_erreur = true
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ montant opération est obligatoire', life: 3000 });
        }
        if (operation.seuil.length === 0) {
            setErreur(true);
            check_erreur = true
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ montant seuil est obligatoire', life: 3000 });
        }
        // vérifier si tous les champs sont reneignées
        if (operation.designation.trim().length === 0) {
            setErreur(true);
            check_erreur = true
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ montant désignation est obligatoire', life: 3000 });
        }

        setSubmitted(true);
        //e.preventDefault();  
        if (check_erreur === true) {
            setModalCreate(true);
        } else {
            const formData = new FormData()
            formData.append("montant_operation", operation.montant_operation)
            formData.append("designation", operation.designation)
            formData.append("description", operation.description)
            formData.append("seuil", operation.seuil)

            await create_operation_portefeuille(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchOperations();
                fetchSolde();
                setModalCreate(false);
                dispatch(updateCreditSolde({'montant_credit':operation.montant_operation}))
                // reset form
                let _operation = { ...operation };
                _operation['montant_operation'] = 0;
                _operation['designation'] = '';
                _operation['description'] = '';
                setOperation(_operation);
            }).catch(({ response }) => {
                if (response.status === 422) {
                    setModalCreate(true);
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                    setModalCreate(true);
                }
            })
            //console.log(erreur)
        }
    }


    const dateUpdatedBodyTemplate = (data) => {
        return moment(data.updated_at).format("YYYY-MM-DD H:m:s");
    };

    const clearFilter = () => {
        initFilters();
    };
    const priceBodyTemplate = (data) => {
        return formatCurrency(data.montant_operation);
    };
    const formatCurrency = (value) => {
        return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            designation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            montant_operation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            description: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updated_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue('');
    };

    const actionBodyTemplate = (data) => {
        return (
            <React.Fragment>
                <div className="flex justify-content-between text-center">
                    <Button severity="danger" type="button" icon="pi pi-trash" label="Supprimer" outlined />
                </div>
            </React.Fragment>
        );
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Effacer" outlined onClick={clearFilter} /> &nbsp;
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Recherche fulltext" />
                </span>&nbsp;&nbsp;
                <Button onClick={() => handleCreate()} type="button" icon="pi pi-wallet" severity="wan" tooltip="Créditer" rounded />
            </div>
        );
    };
    // entête du tablea
    const header = renderHeader();

    return (
        <React.Fragment>
            <Layout>
                <Toast ref={toast} />
                <ConfirmDialog />
                <Divider align="center">
                <span className="p-tag" title="Mon portefeuille" style={{ fontSize: '2rem' }}><i className="pi pi-wallet" style={{ fontSize: '2rem' }}></i>&nbsp; {formatCurrency(solde)}</span>                </Divider>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-md-3">
                                <h6 className="m-0 font-weight-bold text-primary">Historique des recharges</h6>
                            </div>
                            <div className="col-md-9 text-right">
                                <Link href="#" style={{ textDecoration: "none" }} className="active" aria-current="page">Mes recharges</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="card">
                                <DataTable value={operations} paginator showGridlines rows={10} loading={loading} dataKey="id"
                                    filters={filters} globalFilterFields={['designation', 'montant_operation', 'description', 'updated_at']} header={header}
                                    tableStyle={{ minWidth: '50rem' }}
                                    emptyMessage="Aucune données disponibles.">
                                    <Column field="montant_operation" body={priceBodyTemplate} sortable header="Montant opération" filter filterPlaceholder="Recherche par montant" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                    <Column field="designation" sortable header="Désignation" filter filterPlaceholder="Recherche par libellé" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                    <Column field="description" sortable header="Description" filter filterPlaceholder="Recherche par description" style={{ minWidth: '12rem' }} />
                                    <Column field="updated_at" body={dateUpdatedBodyTemplate} sortable header="Date opération" filter style={{ minWidth: '12rem', textAlign: 'center' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>

                {/** formulaire de création */}
                <Dialog visible={modalCreate} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Créditer mon portefeuille" className="p-fluid" onHide={hideModals} footer={modalCreateFooter}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="form-group row">
                                <div className="form-group col-md-4">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            <b>Solde actuel <i className="text-danger">*</i></b>
                                        </label>
                                        <div className="p-inputgroup flex-1">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-wallet"></i>
                                            </span>
                                            <InputNumber placeholder="10" height={1} inputId="code" value={solde} readOnly autoFocus />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            <b>Montant <i className="text-danger">*</i></b>
                                        </label>
                                        <div className="p-inputgroup flex-1">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-wallet"></i>
                                            </span>
                                            <InputNumber placeholder="" height={1} inputId="code" value={operation.montant_operation} onValueChange={(e) => onInputChange(e, 'montant_operation')} required autoFocus />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            <b> Seuil <i className="text-danger">*</i></b>
                                        </label>
                                        <div className="p-inputgroup flex-1">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-wallet"></i>
                                            </span>
                                            <InputNumber placeholder="" height={1} inputId="seuil" value={operation.seuil} onValueChange={(e) => onInputChange(e, 'seuil')} autoFocus />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            <b>Désignation <i className="text-danger">*</i></b>
                                        </label>
                                        <div className="p-inputgroup flex-1">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-book"></i>
                                            </span>
                                            <InputText height={1} id="designation" name="designation" value={operation.designation} onChange={(e) => onInputChange(e, 'designation')} required autoFocus />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            <b>Description <i className="text-danger"></i></b>
                                        </label>
                                        <div className="p-inputgroup flex-1">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-book"></i>
                                            </span>
                                            <InputText height={1} id="description" name="description" value={operation.description} onChange={(e) => onInputChange(e, 'description')} required autoFocus />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                {/** end formulaire de création */}

            </Layout>
        </React.Fragment>
    )
}
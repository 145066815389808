import { api } from "./api";

// liste des terminaisons (webservices) 
  
// authentification
export const authentification = (data) => {
    return api.post(`authentification`, data);
} 

 // getuser
export const getUser = (token) => {
    return api.get(`getuser`,{ headers: { 'Authorization': 'Bearer '+token } });
}

// deconnexion
export const deconnexion = (data, token) => {
    return api.post(`deconnexion`, data,{ headers: { 'Authorization': 'Bearer '+token } });
}
import { api } from "./api";

// liste des terminaisons (webservices) 

// liste
export const liste = (token) => {
    return api.get(`autredepenses`,{ headers: { 'Authorization': 'Bearer '+token } });
} 
 

// create
export const create = (data,token) => {
    return api.post(`autredepenses`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}
 
// delete
export const remove = (data, token) => {
    return api.post(`autredepenses/delete`, data,{ headers: { 'Authorization': 'Bearer '+token } });
}
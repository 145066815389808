import React, { useEffect, useState, useRef } from "react";
import Layout from "../inc/layout";
import { Link } from "react-router-dom";
import { myroute } from "../../Webroute";
import { Button } from 'primereact/button';
// validation des formulaires
import { liste, edit, create, remove } from "../../webservices/users_ws";
import { liste as liste_profils } from "../../webservices/profils_ws";
// date format
import moment from "moment";

// gestion datatable  
import { locale, addLocale, FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { activateComponent, disableComponent } from "../../webservices/api";
import { getUser, isAuthenticated } from "../../webservices/auth_ws";
import Swal from 'sweetalert2';
import { SplitButton } from 'primereact/splitbutton';
import { Password } from 'primereact/password';
import { useSelector } from "react-redux";

// end gestion datatable

export default function IndexUser(props) {

    // vérifier si l'utilisateur est connecter
    //const login = localStorage.getItem("isLoggedIn"); 
    addLocale('fr', {
        "startsWith": "Commence par",
        "contains": "Contient",
        "notContains": "Ne contient pas",
        "endsWith": "Se termine par",
        "equals": "Égal à",
        "notEquals": "Différent de",
        "noFilter": "Aucun filtre",
        "filter": "Filtre",  // only available for PrimeReact
        "lt": "Inférieur à",
        "lte": "Inférieur ou égal à",
        "gt": "Supérieur à",
        "gte": "Supérieur ou égal à",
        "dateIs": "La date est",
        "dateIsNot": "La date n'est pas",
        "dateBefore": "Avant le",
        "dateAfter": "Après le",
        "custom": "Personnalisé",
        "clear": "Effacer",
        "apply": "Appliquer",
        "matchAll": "Correspond à tous",
        "matchAny": "Au moins un Correspond",
        "addRule": "Ajouter une règle",
        "removeRule": "Retirer une règle",
    });

    locale('fr');

    const [statuts, setStatuts] = useState(['OUI', 'NON']);
    const [user, setUser] = useState(null);
    const token = useSelector(state => state.auth.token)
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [utilisateurs, setUtilisateurs] = useState([])
    const [utilisateur, setUtilisateur] = useState({ id: '', nom: '', prenoms: '', email: '', profil_id: '', password:'' })

    const [showModal, setShow] = useState(false);
    const [modalUtilisateur, setModalUtilisateur] = useState(false);
    const [modalCreateUtilisateur, setModalCreateUtilisateur] = useState(false);
    const [modalDeleteUtilisateur, setModalDeleteUtilisateur] = useState(false);
    const [modalDisableUtilisateur, setModalDisableUtilisateur] = useState(false);
    const [modalActivateUtilisateur, setModalActivateUtilisateur] = useState(false);

    const [selectedProfil, setSelectedProfil] = useState(null);
    const [profils, setProfils] = useState([]);

    const [itemDelete, setItemDelete] = useState();
    const [itemId, setItemId] = useState();
    // soumission du formulaire
    const [submitted, setSubmitted] = useState(false);
    const [erreur, setErreur] = useState(false);
    const toast = useRef(null);
    const handleClose = () => setShow(false);

    const hideModals = () => { setModalUtilisateur(false); setSubmitted(false); };
    const hideModalsCreate = () => { setModalCreateUtilisateur(false); setSubmitted(false); };
    const hideModalsDelete = () => setModalDeleteUtilisateur(false);
    const hideModalsDisable = () => setModalDisableUtilisateur(false);
    const hideModalsActivate = () => setModalActivateUtilisateur(false);

    const showModals = () => setModalUtilisateur(true);
    const showModalsDelete = () => setModalDeleteUtilisateur(true);
    const showModalCreate = () => setModalCreateUtilisateur(true)
    const showModalsDisable = () => setModalDisableUtilisateur(true);
    const showModalsActivate = () => setModalActivateUtilisateur(true);

    // déclaration des constantes pour gérer les erreurs
    const [erreurNom, setErreurNom] = useState({ status: false, message: '' })
    const [erreurPrenoms, setErreurPrenoms] = useState({ status: false, message: '' })
    const [erreurEmail, setErreurEmail] = useState({ status: false, message: '' })
    const [erreurTelephone, setErreurTelephone] = useState({ status: false, message: '' })
    const [erreurProfil, setErreurProfil] = useState({ status: false, message: '' })
    const [erreurPassword, setErreurPassword] = useState({ status: false, message: '' })

    const navigate = useNavigate();

    // déclaration des boutons d'action
    const listeAction = (data) => {
        if (data.actif === "OUI") {
            return [
                {
                    label: 'Désactiver',
                    icon: 'pi pi-eye-slash',
                    command: () => handleDisable(data)
                },
                {
                    label: 'Supprimer',
                    icon: 'pi pi-trash',
                    command: () => handleDelete(data)
                }
            ];
        } else {
            return [
                {
                    label: 'Activer',
                    icon: 'pi pi-eye',
                    command: () => handleActivate(data)
                },
                {
                    label: 'Supprimer',
                    icon: 'pi pi-trash',
                    command: () => handleDelete(data)
                }
            ];
        }

    }


    //####### modal create footer 
    const modalCreateFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideModalsCreate} />
            <Button label="Valider" type="submit" icon="pi pi-save" onClick={() => createUtilisateur()} />
        </React.Fragment>
    );
    //###### end modal create footer


    //####### modal edit footer 
    const modalEditFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideModals} />
            <Button label="Valider" type="submit" icon="pi pi-check" onClick={() => saveEdit()} />
        </React.Fragment>
    );
    //###### end modal edit footer

    //####### modal delete footer 
    const modalDeleteFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" outlined onClick={hideModalsDelete} />
            <Button label="Oui" type="submit" icon="pi pi-check" onClick={() => deleteUtilisateur()} />
        </React.Fragment>
    );
    //###### end modal delete footer

    //####### modal disbale footer 
    const modalDisableFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" outlined onClick={hideModalsDisable} />
            <Button label="Oui" type="submit" icon="pi pi-check" onClick={() => disableUtilisateur()} />
        </React.Fragment>
    );
    //###### end modal disbale footer

    //####### modal activate footer 
    const modalActivateFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" outlined onClick={hideModalsActivate} />
            <Button label="Oui" type="submit" icon="pi pi-check" onClick={() => activateUtilisateur()} />
        </React.Fragment>
    );
    //###### end modal activate footer

    // mise à jour automatique de l'objet projet via le formulaire d'édition
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _utilisateur = { ...utilisateur };

        _utilisateur[`${name}`] = val;

        setUtilisateur(_utilisateur);

        // vérifier les données 
        if (name === "nom") {
            if (val.trim().length === 0) {
                setErreurNom({ status: true, message: 'le champ nom est obligatoire' });
            } else {
                setErreurNom({ status: false, message: '' });
            }
        }
        if (name === "prenoms") {
            if (val.trim().length === 0) {
                setErreurPrenoms({ status: true, message: 'le champ prénoms est obligatoire' });
            } else {
                setErreurPrenoms({ status: false, message: '' });
            }
        }
        if (name === "password") {
            if (val.trim().length === 0) {
                setErreurPassword({ status: true, message: 'le champ mot de passe est obligatoire' });
            } else {
                setErreurPassword({ status: false, message: '' });
            }
        }
        if (name === "telephone") {
            if (val.trim().length === 0) {
                setErreurTelephone({ status: true, message: 'le champ téléphone est obligatoire' });
            } else {
                setErreurTelephone({ status: false, message: '' });
            }
        }
        if (name === "email") {
            if (val.trim().length === 0) {
                setErreurEmail({ status: true, message: 'le champ e-mail est obligatoire' });
            } else {
                setErreurEmail({ status: false, message: '' });
            }
        }
        if (name === "profil_id") {
            if (val.trim().length === 0) {
                setErreurProfil({ status: true, message: 'le champ profil est obligatoire' });
            } else {
                setErreurProfil({ status: false, message: '' });
            }
        }
    };

    // mise à jour automatique de l'objet utilisateur->profil_id via le formulaire d'édition
    const onChangeProfil = (value) => {
        let _utilisateur = { ...utilisateur };
        _utilisateur['profil_id'] = value.code;
        setUtilisateur(_utilisateur);
        setSelectedProfil(value);
        if (value.code.length === 0) {
            setErreurProfil({ status: true, message: 'le champ profil est obligatoire' });
        } else {
            setErreurProfil({ status: false, message: '' });
        }
    };

    // afficher le formulaire d'édition 
    const handleShow = (utilisateur) => {
        setSubmitted(false);
        setUtilisateur({ ...utilisateur });
        // afficher le default statut
        setSelectedProfil({ name: utilisateur.libelle_profil, code: utilisateur.profil_id })
        // afficher le modaln

        showModals()

    };

    // afficher le formulaire de création
    const handleCreate = () => {
        setSubmitted(false);
        // initialisation de l'objet
        setUtilisateur({ id: '', nom: '', prenoms: '', telephone: '', email: '', profil_id: '' });
        setSelectedProfil({ name: '', code: '' });
        // afficher le modal 
        showModalCreate()
    };


    // afficher la boîte de confirmation de la suppression de l'élément
    const handleDelete = (utilisateur) => {
        setItemDelete(utilisateur.id)
        showModalsDelete()
    };

    // afficher la boîte de confirmation de la désactivation de l'élément
    const handleDisable = (utilisateur) => {
        setItemId(utilisateur.id)
        showModalsDisable()
    };

    // afficher la boîte de confirmation de la désactivation de l'élément
    const handleActivate = (utilisateur) => {
        setItemId(utilisateur.id)
        showModalsActivate()
    };


    // liste des Utilisateurs  
    const fetchUtilisateurs = async () => { // 
        await liste(token).then(({ data }) => {
            setUtilisateurs(data.users) 
        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.status)
            } else {
                console.log(response.status)
            }
        })
    }

    // récupérer la liste des profil
    const fetchProfils = async () => {
        await liste_profils(token).then(({ data }) => {
            if (data.profils.length > 0) {
                const optionProfil = data.profils.map((option, index) => {
                    //console.log(option.libelle)
                    return ({ name: option.libelle, code: option.id })
                })
                //console.log(optionProfil)
                setProfils(optionProfil)
            }

        }).catch(({ response }) => {
            if (response.status === 422) {
            } else {
                console.log(response)
            }
        })
    }

    useEffect(() => { 

        fetchUtilisateurs();
        initFilters();
        fetchProfils();
    }, [navigate])


    // script d'enregistrement des données
    const createUtilisateur = async () => {
        setErreur(false);
        let check_erreur = false
        // vérifier si tous les champs sont reneignées
        if (utilisateur.nom.trim().length === 0) {
            setErreur(true);
            check_erreur = true

            setErreurNom({ status: true, message: 'le champ nom est obligatoire' });

        }
        if (utilisateur.password.trim().length === 0) {
            setErreur(true);
            check_erreur = true

            setErreurPassword({ status: true, message: 'le champ mot de passe est obligatoire' });

        }
        if (utilisateur.prenoms.trim().length === 0) {
            setErreur(true);
            check_erreur = true

            setErreurPrenoms({ status: true, message: 'le champ prénoms est obligatoire' });

        }

        if (utilisateur.telephone.trim().length === 0) {
            setErreur(true);
            check_erreur = true
            setErreurTelephone({ status: true, message: 'le champ téléphone est obligatoire' });

        }

        if (utilisateur.email.trim().length === 0) {
            setErreur(true);
            check_erreur = true

            setErreurEmail({ status: true, message: 'le champ e-mail est obligatoire' });

        }


        if (utilisateur.profil_id.length === 0) {
            setErreur(true);
            check_erreur = true
            //toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ statut est obligatoire', life: 3000 });
            setErreurProfil({ status: true, message: 'le champ profil est obligatoire' });

        }

        setSubmitted(true);
        //e.preventDefault(); 

        if (check_erreur === true) {
            setModalCreateUtilisateur(true);
        } else {
            const formData = new FormData()
            formData.append("nom", utilisateur.nom)
            formData.append("prenoms", utilisateur.prenoms)
            formData.append("telephone", utilisateur.telephone)
            formData.append("email", utilisateur.email)
            formData.append("password", utilisateur.password)
            formData.append("profil_id", utilisateur.profil_id)
            console.log(formData)

            await create(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchUtilisateurs();
                setModalCreateUtilisateur(false);
            }).catch(({ response }) => {
                if (response.status === 422) {
                    setModalCreateUtilisateur(true);
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                    setModalCreateUtilisateur(true);
                }
            })
            //console.log(erreur)
        }
    }

    // script de modification des données
    const saveEdit = async () => {
        setErreur(false);
        // vérifier si tous les champs sont reneignées
        if (utilisateur.nom.trim().length === 0) {
            setErreur(true);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ nom est obligatoire', life: 3000 });

        }
        if (utilisateur.prenoms.trim().length === 0) {
            setErreur(true);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ prénoms est obligatoire', life: 3000 });

        }
        if (utilisateur.telephone.trim().length === 0) {
            setErreur(true);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ téléphone est obligatoire', life: 3000 });

        }
        if (utilisateur.email.trim().length === 0) {
            setErreur(true);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ e-mail est obligatoire', life: 3000 });

        }  
        if (utilisateur.profil_id === "") {
            setErreur(true);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'le champ profil est obligatoire', life: 3000 });
        }
        setSubmitted(true);
        //e.preventDefault(); 

        if (erreur) {
            setModalUtilisateur(true);
        } else {
            const formData = new FormData()
            formData.append("identifiant", utilisateur.id)
            formData.append("nom", utilisateur.nom)
            formData.append("prenoms", utilisateur.prenoms)
            formData.append("telephone", utilisateur.telephone)
            formData.append("email", utilisateur.email)
            formData.append("profil_id", utilisateur.profil_id)

            await edit(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchUtilisateurs();
                setModalUtilisateur(false);
            }).catch(({ response }) => {
                if (response.status === 422) {
                    setModalUtilisateur(true);
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });

                    setModalUtilisateur(true);
                }
            })
            //console.log(erreur)
        }
    }

    // script de suppression des données
    const deleteUtilisateur = async () => {
        if (itemDelete !== null) {
            const formData = new FormData();
            formData.append("identifiant", itemDelete)
            await remove(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchUtilisateurs();
            }).catch(({ response }) => {
                if (response.status === 422) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                }
            })

        } else {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Type opération non reconnu", life: 3000 });
        }
        // cacher le modal
        setItemDelete(null);
        setModalDeleteUtilisateur(false);

    }


    // script de désactivation des données
    const disableUtilisateur = async () => {
        if (itemId !== null) {
            const formData = new FormData();
            formData.append("identifiant", itemId)
            formData.append("table", "users")
            await disableComponent(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchUtilisateurs();
            }).catch(({ response }) => {
                if (response.status === 422) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                }
            })

        } else {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Type opération non reconnu", life: 3000 });
        }
        // cacher le modal
        setItemId(null);
        setModalDisableUtilisateur(false);

    }

    // script d'activation des données
    const activateUtilisateur = async () => {
        if (itemId !== null) {
            const formData = new FormData();
            formData.append("identifiant", itemId)
            formData.append("table", "users")
            await activateComponent(formData, token).then(({ data }) => {
                toast.current.show({ severity: 'success', summary: 'Succès', detail: data.message, life: 3000 });
                fetchUtilisateurs();
            }).catch(({ response }) => {
                if (response.status === 422) {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: response.data.message, life: 3000 });
                }
            })

        } else {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Type catégorie non reconnu", life: 3000 });
        }
        // cacher le modal
        setItemId(null);
        setModalActivateUtilisateur(false);

    }



    const dateUpdatedBodyTemplate = (data) => {
        return moment(data.updated_at).format("DD/MM/YYYY H:m:s") + ' par ' + data.name;
    };

    // custom statut badge
    const statutBodyTemplate = (data) => {
        return <Tag value={data.actif} severity={getSeverity(data)}></Tag>;
    };

    const statusItemTemplate = (data) => { 
        return <Tag value={data} severity={getSeverityCustom(data)} />;
    };

    const statusRowFilterTemplate = (data) => { 
        return (
            <Dropdown value={data.value} options={statuts} onChange={(e) => data.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Choisissez une option" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    }; 

    const getSeverity = (data) => {
        switch (data.actif) {
            case "OUI":
                return 'success';

            default:
                return 'danger';
        }
    };

    const getSeverityCustom = (data) => { 
        switch (data) {
            case "OUI":
                return 'success';

            default:
                return 'danger';
        }
    };
 
    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            nom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            prenoms: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            telephone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            libelle_profil: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            updated_at: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            actif: { value: null, matchMode: FilterMatchMode.EQUALS  },
        });
        setGlobalFilterValue('');
    };

    const actionBodyTemplate = (data) => {
        return (
            <React.Fragment>
                <div className="flex justify-content-between text-center">
                    <SplitButton label="" icon="pi pi-pencil" onClick={() => handleShow(data)} model={listeAction(data)} severity="secondary" rounded />
                </div>
            </React.Fragment>
        );
    };


    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Effacer" outlined onClick={clearFilter} /> &nbsp;
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Recherche fulltext" />
                </span>&nbsp;&nbsp;
                <Button onClick={() => handleCreate()} type="button" icon="pi pi-save" severity="wan" tooltip="Ajouter" rounded />
            </div>
        );
    };
    // entête du tablea
    const header = renderHeader();

    return (
        <React.Fragment>
            <Layout>
                <Toast ref={toast} />
                <ConfirmDialog />
                <h1 className="h3 mb-2 text-gray-800">Utilisateurs</h1>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-md-3">
                                <h6 className="m-0 font-weight-bold text-primary">Liste des utilisateurs</h6>
                            </div>
                            <div className="col-md-9 text-right">
                                <Link to={myroute.parametres}>Paramètres</Link>&nbsp; / &nbsp;
                                <Link href="#" style={{ textDecoration: "none" }} className="active" aria-current="page">Utilisateurs</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="card">
                                <DataTable value={utilisateurs} paginator filterDisplay="row" rows={10} loading={loading} dataKey="id"
                                    filters={filters}  globalFilterFields={['nom', 'prenoms', 'telephone', 'email', 'libelle_profil', 'updated_at','actif']} header={header}
                                    tableStyle={{ minWidth: '50rem' }}
                                    emptyMessage="Aucune données disponibles.">
                                    <Column field="nom" sortable header="Nom" filter filterPlaceholder="Recherche par nom" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                    <Column field="prenoms" sortable header="Prénoms" filter filterPlaceholder="Recherche par prénoms" style={{ minWidth: '12rem' }} />
                                    <Column field="telephone" sortable header="Téléphone" filter filterPlaceholder="Recherche par téléphone" style={{ minWidth: '12rem' }} />
                                    <Column field="email" sortable header="E-mail" filter filterPlaceholder="Recherche par e-mail" style={{ minWidth: '12rem' }} />
                                    <Column field="libelle_profil" sortable header="Statut" filter filterPlaceholder="Recherche par profil" style={{ minWidth: '12rem', textAlign: 'center' }} />
                                    <Column field="updated_at" body={dateUpdatedBodyTemplate} sortable header="Date modification" filter style={{ minWidth: '12rem' }} />
                                    <Column field="actif" header="Status" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem', textAlign: 'center' }} body={statutBodyTemplate} filter filterElement={statusRowFilterTemplate} />
                                    <Column body={actionBodyTemplate} headerStyle={{ textAlign: 'center' }} header="Action" exportable={false} style={{ minWidth: '8rem' }}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>

                {/** formulaire de création */}
                <Dialog visible={modalCreateUtilisateur} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Ajouter un utilisateur" className="p-fluid" onHide={hideModalsCreate} footer={modalCreateFooter}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="form-group row">
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            Nom <i className="text-danger">*</i>
                                        </label>
                                        <InputText height={1} id="nom" name="nom" value={utilisateur.nom} onChange={(e) => onInputChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': erreurNom.status })} />
                                        {erreurNom.status && <small className="p-error">{erreurNom.message}</small>}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            Prénoms <i className="text-danger">*</i>
                                        </label>
                                        <InputText height={1} id="prenoms" name="prenoms" value={utilisateur.prenoms} onChange={(e) => onInputChange(e, 'prenoms')} required autoFocus className={classNames({ 'p-invalid': erreurPrenoms.status })} />
                                        {erreurPrenoms.status && <small className="p-error">{erreurPrenoms.message}</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            Téléphone <i className="text-danger">*</i>
                                        </label>
                                        <InputText height={1} id="telephone" name="telephone" value={utilisateur.telephone} onChange={(e) => onInputChange(e, 'telephone')} required autoFocus className={classNames({ 'p-invalid': erreurTelephone.status })} />
                                        {erreurTelephone.status && <small className="p-error">{erreurTelephone.message}</small>}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            E-mail <i className="text-danger">*</i>
                                        </label>
                                        <InputText type="email" height={1} id="email" name="email" value={utilisateur.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': erreurEmail.status })} />
                                        {erreurEmail.status && <small className="p-error">{erreurEmail.message}</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                            <div className="form-group col-md-6"> 
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Profil
                                    </label>
                                    <Dropdown required placeholder="Choisissez une option" name="profil_id" inputId="profil_id" value={selectedProfil} onChange={(e) => onChangeProfil(e.value)} className={classNames({ 'p-invalid': erreurProfil.status })} options={profils} optionLabel="name"
                                        filter />
                                    {erreurProfil.status && <small className="p-error">{erreurProfil.message}</small>}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            Mot de passe <i className="text-danger">*</i>
                                        </label>
                                        <Password toggleMask value={utilisateur.password} id="password" name="password" onChange={(e) => onInputChange(e, 'password')} required={true}  autoFocus className={classNames({ 'p-invalid': erreurPassword.status })} />
                                         {erreurPassword.status && <small className="p-error">{erreurPassword.message}</small>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Dialog>
                {/** end formulaire de création */}

                {/** formulaire de modification **/}

                <Dialog visible={modalUtilisateur} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Modifier les infos d'utilisateur" className="p-fluid" onHide={hideModals} footer={modalEditFooter}>
                <div className="confirmation-content">
                        <div className="row">
                            <div className="form-group row">
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            Nom <i className="text-danger">*</i>
                                        </label>
                                        <InputText height={1} id="nom" name="nom" value={utilisateur.nom} onChange={(e) => onInputChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': erreurNom.status })} />
                                        {erreurNom.status && <small className="p-error">{erreurNom.message}</small>}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            Prénoms <i className="text-danger">*</i>
                                        </label>
                                        <InputText height={1} id="prenoms" name="prenoms" value={utilisateur.prenoms} onChange={(e) => onInputChange(e, 'prenoms')} required autoFocus className={classNames({ 'p-invalid': erreurPrenoms.status })} />
                                        {erreurPrenoms.status && <small className="p-error">{erreurPrenoms.message}</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            Téléphone <i className="text-danger">*</i>
                                        </label>
                                        <InputText height={1} id="telephone" name="telephone" value={utilisateur.telephone} onChange={(e) => onInputChange(e, 'telephone')} required autoFocus className={classNames({ 'p-invalid': erreurTelephone.status })} />
                                        {erreurTelephone.status && <small className="p-error">{erreurTelephone.message}</small>}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="field">
                                        <label htmlFor="name" className="font-bold">
                                            E-mail <i className="text-danger">*</i>
                                        </label>
                                        <InputText type="email" height={1} id="email" name="email" value={utilisateur.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': erreurEmail.status })} />
                                        {erreurEmail.status && <small className="p-error">{erreurEmail.message}</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12"> 
                                <div className="field">
                                    <label htmlFor="name" className="font-bold">
                                        Profil
                                    </label>
                                    <Dropdown required placeholder="Choisissez une option" name="profil_id" inputId="profil_id" value={selectedProfil} onChange={(e) => onChangeProfil(e.value)} className={classNames({ 'p-invalid': erreurProfil.status })} options={profils} optionLabel="name"
                                        filter />
                                    {erreurProfil.status && <small className="p-error">{erreurProfil.message}</small>}
                                </div>
                            </div>

                        </div>
                    </div>
                </Dialog>
                {/** end formulaire de modification **/}

                {/** boîte de supression **/}
                <Dialog visible={modalDeleteUtilisateur} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmation" modal footer={modalDeleteFooter} onHide={hideModalsDelete}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {utilisateur && <span>Êtes-vous sûre de vouloir supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                {/** end boîte de supression **/}

                {/** boîte de désactivation **/}
                <Dialog visible={modalDisableUtilisateur} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmation" modal footer={modalDisableFooter} onHide={hideModalsDisable}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {utilisateur && <span>Êtes-vous sûre de vouloir désactiver cet élément ?</span>}
                    </div>
                </Dialog>
                {/** end boîte de désactivation **/}

                {/** boîte d'activation **/}
                <Dialog visible={modalActivateUtilisateur} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmation" modal footer={modalActivateFooter} onHide={hideModalsActivate}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {utilisateur && <span>Êtes-vous sûre de vouloir activer cet élément ?</span>}
                    </div>
                </Dialog>
                {/** end boîte d'activation **/}
            </Layout>
        </React.Fragment>
    )
}
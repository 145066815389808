import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { deconnexion, getUser, isAuthenticated } from "../../webservices/auth_ws";  
import { useDispatch, useSelector } from "react-redux";
import { checkAuthenticate, resetAuthData } from "../app/providers/authSlice";
import { myroute } from "../../Webroute";

export default function Topbar(props) { 

    const auth = useSelector(state => state.auth)
    const solde = auth.solde ? auth.solde  : 0
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formatCurrency = (value) => {
        return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
    };

    // check if user is authenticate
	dispatch(checkAuthenticate()) 

// Vérifier si le token existe et n'est pas expiré 
if (!auth.isAuthenticate) {
	//deconnecter l'utilisateur et renvoie sur login page 
	dispatch(resetAuthData())
	navigate(myroute.login)
}	

    useEffect(() => {
        
    }, [navigate]);
  

const  onLogoutHandler = () => {
    let check_logout = logoutBackend();
    if (check_logout) { 
        dispatch(resetAuthData()) 
        // redirect login 
    }else{

    }

    };
      
 
  const logoutBackend = async () => { 
      const formData = new FormData()   
      formData.append("email",auth.user.email)
      await deconnexion(formData,auth.token).then(({data})=>{
      //console.log(data)
        if (data.success) {  
          return true; 
        }else{ 
            return false
        }
  
        // navigate("/statuts")
      }).catch(({response})=>{
        return false
      }) 
    }

    return(
        <React.Fragment>
<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

{/** Sidebar Toggle (Topbar) **/}
<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
    <i className="fa fa-bars"></i>
</button>

{/** Topbar Search **/}
<form
    className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
    <div className="input-group">
        <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
            aria-label="Search" aria-describedby="basic-addon2"/>
        <div className="input-group-append">
            <button className="btn btn-primary" type="button">
                <i className="fas fa-search fa-sm"></i>
            </button>
        </div>
    </div>
</form>

{/** Topbar Navbar **/}
<ul className="navbar-nav ml-auto">

    {/** Nav Item - Search Dropdown (Visible Only XS) **/}
    <li className="nav-item dropdown no-arrow d-sm-none">
        <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-search fa-fw"></i>
        </a>
        {/** Dropdown - Messages **/}
        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
            aria-labelledby="searchDropdown">
            <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small"
                        placeholder="Search for..." aria-label="Search"
                        aria-describedby="basic-addon2"/>
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button">
                            <i className="fas fa-search fa-sm"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </li>

    {/** Nav Item - Alerts **/}
    <li className="nav-item dropdown no-arrow mx-1">
        <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-bell fa-fw"></i>
            {/** Counter - Alerts **/}
            <span className="badge badge-danger badge-counter">0</span>
        </a>
        {/** Dropdown - Alerts **/}
        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="alertsDropdown">
            <h6 className="dropdown-header">
                Alerts 
            </h6>
            {/**
             <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="mr-3">
                    <div className="icon-circle bg-primary">
                        <i className="fas fa-file-alt text-white"></i>
                    </div>
                </div>
                <div>
                    <div className="small text-gray-500">December 12, 2019</div>
                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                </div>
            </a>
             *  ***/} 
            <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
        </div>
    </li>

    {/** Nav Item - Messages **/}
    <li className="nav-item dropdown no-arrow mx-1">
        <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
            <span className="p-tag" title="Mon portefeuille" style={{ fontSize: '0.8rem' }}><i className="pi pi-wallet" style={{ fontSize: '0.8rem' }}></i>&nbsp; {formatCurrency(solde)} </span>
        </a>
        {/** Dropdown - Messages **/}
        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="messagesDropdown">
            <h6 className="dropdown-header">
                Message Center
            </h6>
              
            <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="dropdown-list-image mr-3">
                    <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                        alt="..."/>
                    <div className="status-indicator bg-success"></div>
                </div> 
            </a>
            <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
        </div>
    </li>

    <div className="topbar-divider d-none d-sm-block"></div>

    {/** Nav Item - User Information **/}
    <li className="nav-item dropdown no-arrow">
        <Link className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small"> {auth.user?.name} </span>
            <img className="img-profile rounded-circle"
                src="theme/img/undraw_profile.svg"/>
        </Link>
        {/** Dropdown - User Information **/}
        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"> 
            <div className="dropdown-divider"></div>
            <Link className="dropdown-item" href="#" onClick={onLogoutHandler} >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Se déconnecter
            </Link>
        </div>
    </li>

</ul>

</nav> 
        </React.Fragment>
    )
}
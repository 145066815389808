import { api } from "./api";

// liste des terminaisons (webservices) 

// liste
export const liste = (token) => {
    return api.get(`categories`,{ headers: { 'Authorization': 'Bearer '+token } });
}

// show
export const show = (data,token) => {
    return api.post(`categories/show`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}

// create
export const create = (data,token) => {
    return api.post(`categories`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}
// edit
export const edit = (data, token) => {
    return api.post(`categories/edit`, data,{ headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+token } });
}
// delete
export const remove = (data, token) => {
    return api.post(`categories/delete`, data,{ headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+token } });
}